import React, {useEffect, useState} from 'react'
import {FormProvider, useForm, useFormContext, Controller} from 'react-hook-form'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton'
import ClientService from "../../../../services/ClientService"
import ClientContext from "../../../../providers/CurrentClientProvider"
import constants from '../../../../constants/constant'
import {associateMarketerList, posSystemList, fiscalYearList} from './constants'
import ClientStatusModal from './ClientStatusModal';
import TipContext from '../../../../providers/GlobalTip';
import {
    Typography,
    TextField,
    Button,
    NativeSelect,
    InputLabel,
    FormControlLabel,
    Switch,
    Card,
    CardHeader,
    CardContent,
    FormHelperText,
    FormControl
} from "@mui/material"
import { trackEvent } from '../../../../services/AmplitudeService';

const cssInJs = {
    header: {
        backgroundColor: "#422e52",
        color: "#fff"
    },
    subheader: {
        color: "#fff"
    },
    headerIcon: {
        backgroundColor: '#f97125',
        color: 'white',
        boxShadow: 'rgba(0,0,0,0.45) 0px 2px 7px 0px',
        position: 'relative',
        top: '41px',
        marginRight: '10px',
        "&:hover": {
            backgroundColor: "#f85e07",
            boxShadow: 'rgba(0,0,0,0.65) 0px 2px 12px 0px',
        },
    },
    warning: {
        backgroundColor: '#FFE264',
        marginTop: '-16px',
        marginBottom: 0,
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '10px',
        fontSize: '13px'
    },
    textInput: {
        marginBottom: '15px'
    },
    textInputThreeInRowItem: {
        width: '30%'
    },
    select: {
        "&:hover": {
            backgroundColor: "#fff",
        },
        marginBottom: '15px',
    },
    formHeading: {
        fontSize: '15px',
        fontWeight: '500'
    },
}

export const ConnectForm = ({children}) => {
    const methods = useFormContext();

    return children({...methods});
};

const GeneralClientEditForm = () => {
    const [formIsVisible, setFormIsVisible] = useState(true)
    const [clientData, setClientData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isSharedBilling, setIsSharedBilling] = useState(false)
    const [hasShuttle, setHasShuttle] = useState(false)
    const [hasHeadCount, setHasHeadCount] = useState(false)
    const [isClientStatusModalVisible, setIsClientStatusModalVisible] = useState(false)
    const [formErrors, setFormErrors] = useState([])
    const [hasSaved, setHasSaved] = useState(false)
    const tip_context = TipContext.useMessage()

    const client_context = ClientContext.useClient()

    const defaultValues = {}
    if (clientData) {
        defaultValues.client_code = clientData.ClientCode
    }

    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            ...defaultValues,
            changeDate: '',
            FiscalYearDate: '12/31'
        }
    })

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        trigger,
        formState: {
            errors,
            isDirty
        },
        control
    } = methods

    const handleSharedBillingChange = (event) => {
        setIsSharedBilling(isSharedBilling => !isSharedBilling);
    }

    const handleHasShuttleChange = (event) => {
        setHasShuttle(hasShuttle => !hasShuttle)
        setValue('ShuttleRate', 0)
        setValue('HasShuttle', !hasShuttle)
    }

    const handleHasHeadCountChange = (event) => {
        setHasHeadCount(hasHeadCount => !hasHeadCount)
        setValue('HeadCountRate', null)
        setValue('HasHeadCount', !hasHeadCount)
    }

    const toggleClientStatusModal = () => {
        setIsClientStatusModalVisible(prev => !prev)
    }

    const onSubmit = async (formData) => {
        setFormErrors([])
        try {
            const client = await ClientService.updateById(clientData.Id, formData)
            client_context.setClient(client)
            // setFormIsVisible(false) // removed so form stays open at Sarah's request
            setHasSaved(true)

            tip_context.showSuccess('Client updated successfully')

            trackEvent('Client Setup: Updated Profile', {
                clientCode: client.ClientCode
            })
        } catch (e) {
            let er = e.response?.data.message
            if (!er) {
                console.log(e)
            } else {
                tip_context.show(er)
            }
        }
        // const sample_client_code = await ClientService.save(formData)

    }

    // use effect, when form visibilty changes, set hasSaved to false
    useEffect(() => {
        if (!formIsVisible && isDirty && hasSaved) {
            setHasSaved(false)
        } else if (!formIsVisible && !isDirty && !hasSaved) {
            setHasSaved(true)
        }
    }, [formIsVisible])

    useEffect(() => {
        if (!client_context.client) return

        const fetchClientByCode = async (client_code) => {
            try {
                setIsLoading(true)
                const client = await ClientService.getClientByCode(client_code, 1)
                setClientData(client)

                setValue('ClientCode', client.ClientCode)
                setValue('Name', client.Name)

                // Primary Address
                setValue('LegalName', client.LegalName);
                setValue('Address1', client.Address1);
                setValue('Address2', client.Address2);
                setValue('City', client.City);
                setValue('State', client.State);
                setValue('ZipCode', client.ZipCode);
                setValue('Province', client.Province);
                setValue('Country', client.Country);

                // Contact Details
                setValue('LegalName', client.LegalName);
                setValue('Email', client.Email);
                setValue('Phone', client.Phone);
                setValue('Mobile', client.Mobile);
                setValue('instagram', client.instagram);
                setValue('Website', client.Website);
                setValue('Twitter', client.Twitter);
                setValue('Facebook', client.Facebook);

                // Technical
                setValue('AssociateMarketerId', client.AssociateMarketerId);
                setValue('PointOfSaleId', client.PointOfSaleId);
                setValue('FiscalYearType', client.FiscalYearType);
                setValue('FiscalYearDate', client.FiscalYearDate);
                setValue('Imu', client.Imu);
                setValue('HasCashFlowIssue', client.HasCashFlowIssue);
                setValue('HasPlanOnDemand', client.HasPlanOnDemand);
                setValue('HasForeignCurrency', client.HasForeignCurrency);
                setValue('CompanyFounded', client.CompanyFounded);
                setValue('AuthKey', client.AuthKey);

                // Acquisition Details
                setValue('ReferredBy', client.ReferredBy);

                // Notes
                setValue('Notes', client.Notes);
                setValue('CompetitionNotes', client.CompetitionNotes);

                // Commercial
                setValue('ActiveDate', client.ActiveDate);
                setValue('BillingStartDate', client.BillingStartDate);
                setValue('IsBillingManagementOne', client.IsBillingManagementOne);

                // Rates
                setValue('OtbRate', client.OtbRate);
                setValue('PnotbRate', client.PnotbRate);
                setValue('NotbRate', client.NotbRate);
                setValue('OtbSetupRate', client.OtbSetupRate);
                setValue('PnotbSetupRate', client.PnotbSetupRate);
                setValue('NotbSetupRate', client.NotbSetupRate);

                // Integrations
                setValue('HasShuttle', client.HasShuttle == 1);
                setValue('ShuttleRate', client.ShuttleRate);
                setValue('HasHeadCount', client.HasHeadCount == 1);
                setValue('HeadCountRate', client.HeadCountRate);

                setHasShuttle(client.HasShuttle)
                setHasHeadCount(client.HasHeadCount)

                // Admin
                setValue('IsManualEntry', client.IsManualEntry);
                setValue('IsTest', client.IsTest);
                setValue('IsBeta', client.IsBeta);
                setValue('IsVip', client.IsVip);
                // setValue('HasSharedCustom', client.HasSharedCustom);
                // setValue('HasFaves', client.HasFaves);

                // POS API Settings
                setValue('ApiKey', client.ApiKey);
                setValue('ApiUrl', client.ApiUrl);
                setValue('ApiField', client.ApiField);

                // processing rules from DataProcessingException column on Client table (stored as key value json)
                if (client.DataProcessingException) {
                    const parsedDataProcessingException = JSON.parse(client.DataProcessingException)

                    // Processing Rules
                    setValue('DataProcessingException.DoAutomaticFinalizations', parsedDataProcessingException?.DoAutomaticFinalizations ?? 1);
                    setValue('DataProcessingException.DoNegativeAdjustments', parsedDataProcessingException?.DoNegativeAdjustments);
                    setValue('DataProcessingException.HasMarkdownInventoryAdjustments', parsedDataProcessingException?.HasMarkdownInventoryAdjustments);
                    setValue('DataProcessingException.DoPerpetuateInventory', parsedDataProcessingException?.DoPerpetuateInventory);
                    setValue('DataProcessingException.PeriodFromTransactionDate', parsedDataProcessingException?.PeriodFromTransactionDate);
                } else {
                    setValue('DataProcessingException.DoAutomaticFinalizations', 1)
                }

                setValue('periodOffset', client.periodOffset);

                setIsLoading(false);
                trigger()
            } catch (error) {
                console.log(error)
            }
        };

        if (typeof client_context.client === "string") {
            fetchClientByCode(client_context.client)
        } else if (typeof client_context.client === "object") {
            fetchClientByCode(client_context.client.ClientCode)
        }
    }, [client_context.client])

    const selectedAccountStatusValue = watch("selectedAccountStatus");

    const fiscalYearType = watch("fiscalYearType");
    const showFiveWeekMonths = fiscalYearType === '4-5-4' || fiscalYearType === 'Aramark'

    if (!client_context.client) {
        return <>Loading...</>
    }

    if (isLoading) {
        return <>Loading client...</>
    }

    if (!clientData) {
        return <>No client data</>
    }

    return (
        <Card>
            <CardHeader
                title={clientData.Name}
                subheader={clientData.ClientCode}
                subheaderTypographyProps={{sx: cssInJs.subheader}}
                action={
                    <IconButton aria-label="settings" sx={cssInJs.headerIcon} onClick={() => setFormIsVisible(prev => !prev)}>
                        {formIsVisible
                            ? <KeyboardArrowUpIcon/>
                            : <EditIcon/>}
                    </IconButton>
                }
                sx={cssInJs.header}
            />
            <CardContent>
                {(isDirty && !hasSaved) ?
                    <Typography sx={cssInJs.warning}>
                        You have unsaved changes, please hit save before continuing.
                    </Typography>
                : null}
                {
                    !!formErrors.length &&
                        formErrors.map(((item, i) => {
                            return <p key={'error_' + i}>{item}</p>
                        }))
                }
                {formIsVisible && <FormProvider {...methods} >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}>
                            General
                        </Typography>
                        <TextField
                            id="client-code"
                            label="Client code"
                            variant="standard"
                            fullWidth
                            sx={cssInJs.textInput}
                            {...register('ClientCode', {
                                required: "Name is required"
                            })}
                            error={Boolean(errors.ClientCode)}
                            helperText={errors.ClientCode?.message}
                        />
                        <TextField
                            id="name"
                            label="Name"
                            variant="standard"
                            fullWidth
                            {...register('Name', {
                                required: "Name is required"
                            })}
                            sx={cssInJs.textInput}
                            error={Boolean(errors.Name)}
                            helperText={errors.Name?.message}
                        />

                        <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}>
                            Primary address
                        </Typography>
                        <TextField
                            id="address-1"
                            label="Address 1"
                            variant="standard"
                            fullWidth
                            {...register('Address1')}
                            sx={cssInJs.textInput}
                        />
                        <TextField
                            id="address-2"
                            label="Address 2"
                            variant="standard"
                            fullWidth
                            {...register('Address2')}
                            sx={cssInJs.textInput}
                        />
                        <TextField
                            id="city"
                            label="City"
                            variant="standard"
                            fullWidth
                            {...register('City')}
                            sx={cssInJs.textInput}
                        />

                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            State
                        </InputLabel>
                        <NativeSelect
                            variant="standard"
                            sx={cssInJs.select}
                            fullWidth
                            {...register('State')}
                        >
                            <option value={''}>Select State</option>
                            {constants.STATES.map(state => <option key={state} value={state}>{state}</option>)}
                        </NativeSelect>
                        <TextField
                            id="zip-code"
                            label="Zip Code"
                            variant="standard"
                            fullWidth
                            {...register('ZipCode')}
                            sx={cssInJs.textInput}
                        />
                        <TextField
                            id="province"
                            label="Province"
                            variant="standard"
                            fullWidth
                            {...register('Province')}
                            sx={cssInJs.textInput}
                        />
                        <TextField
                            id="country"
                            label="Country"
                            variant="standard"
                            fullWidth
                            {...register('Country')}
                            sx={cssInJs.textInput}
                        />

                        <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}>
                            Contact details
                        </Typography>
                        <div>
                            <TextField
                                id="legal-name"
                                label="Legal Name"
                                variant="standard"
                                fullWidth
                                {...register('LegalName')}
                                sx={cssInJs.textInput}
                            />
                            <TextField
                                id="email"
                                label="Email"
                                variant="standard"
                                fullWidth
                                {...register('Email')}
                                sx={cssInJs.textInput}
                            />
                            <TextField
                                id="phone"
                                label="Phone"
                                variant="standard"
                                fullWidth
                                {...register('Phone')}
                                sx={cssInJs.textInput}
                            />
                            <TextField
                                id="mobile"
                                label="Mobile"
                                variant="standard"
                                fullWidth
                                {...register('Mobile')}
                                sx={cssInJs.textInput}
                            />
                            <TextField
                                id="fax"
                                label="Fax"
                                variant="standard"
                                fullWidth
                                {...register('Fax')}
                                sx={cssInJs.textInput}
                            />
                            <TextField
                                id="skype"
                                label="Skype"
                                variant="standard"
                                fullWidth
                                {...register('Skype')}
                                sx={cssInJs.textInput}
                            />
                            <TextField
                                id="website"
                                label="Website"
                                variant="standard"
                                fullWidth
                                {...register('Website')}
                                sx={cssInJs.textInput}
                            />
                            <TextField
                                id="twitter"
                                label="Twitter"
                                variant="standard"
                                fullWidth
                                {...register('Twitter')}
                                sx={cssInJs.textInput}
                            />
                            <TextField
                                id="facebook"
                                label="Facebook"
                                variant="standard"
                                fullWidth
                                {...register('Facebook')}
                                sx={cssInJs.textInput}
                            />
                        </div>

                        <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}>
                            Technical
                        </Typography>
                        <div>
                            <div>
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Associate Marketer
                                </InputLabel>
                                <NativeSelect
                                    variant="standard"
                                    sx={cssInJs.select}
                                    fullWidth
                                    {...register('AssociateMarketerId')}
                                >
                                    <option key="select-associate-marketer" value={''}>Select Associate Marketer</option>
                                    {/* associateMarketerList */}
                                    {associateMarketerList.map(listItem => <option key={listItem.id}
                                                                                   value={listItem.id}>{listItem.name}</option>)}
                                </NativeSelect>
                            </div>
                            <div>
                                <FormControl fullWidth error={!!errors.PointOfSaleId}>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                        POS System *
                                    </InputLabel>
                                    <NativeSelect
                                        variant="standard"
                                        sx={cssInJs.select}
                                        fullWidth
                                        {...register('PointOfSaleId', {required: 'Point of sale is required'})}
                                    >
                                        <option key="pos-system-title" value={''}></option>
                                        {posSystemList.map(listItem => <option key={listItem.id}
                                                                            value={listItem.id}>{listItem.name}</option>)}
                                    </NativeSelect>
                                    {errors.PointOfSaleId && <FormHelperText>{errors.PointOfSaleId.message}</FormHelperText>}
                                </FormControl>
                            </div>

                            <div>
                                <FormControl fullWidth error={!!errors.FiscalYearType}>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                        Fiscal Year Type *
                                    </InputLabel>
                                    <NativeSelect
                                        variant="standard"
                                        sx={cssInJs.select}
                                        fullWidth
                                        {...register('FiscalYearType', {required: 'Fiscal year type is required'})}
                                    >
                                        {fiscalYearList.map(listItem => <option key={listItem.value}
                                                                                value={listItem.value}>{listItem.value}</option>)}
                                    </NativeSelect>
                                    {errors.FiscalYearType && <FormHelperText>{errors.FiscalYearType.message}</FormHelperText>}
                                </FormControl>
                            </div>

                            {showFiveWeekMonths && <TextField
                                id="fiveWeekMonths"
                                label="5 Week Months"
                                variant="standard"
                                placeholder="(e.g. 1,4,7,11)"
                                fullWidth
                                {...register('FiveWeekMonths')}
                                sx={cssInJs.textInput}
                            />}

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Fiscal Year End"
                                    inputFormat="MMM dd"
                                    format="MM/dd"
                                    views={['month', 'day']}
                                    onChange={(date) => setValue('FiscalYearDate', date)}
                                    value={watch('FiscalYearDate')}
                                    renderInput={(params) => <TextField {...params}
                                                                        fullWidth
                                                                        variant="standard"
                                                                        size="small"
                                                                        error={!!errors.FiscalYearDate}
                                                                        helperText={errors.FiscalYearDate?.message}
                                                                        sx={{mt: 2}}
                                                                        {...register('FiscalYearDate', {required: 'Fiscal year date is required'})}
                                    />}
                                />
                            </LocalizationProvider>

                            <TextField
                                id="imu"
                                label="Default IMU"
                                variant="standard"
                                fullWidth
                                {...register('Imu')}
                                sx={cssInJs.textInput}
                            />
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <FormControlLabel
                                    control={<Switch {...register('HasCashFlowIssue')} name="HasCashFlowIssue" checked={watch('HasCashFlowIssue')} />}
                                    label="Has Cash Flow Issue"
                                />
                                <FormControlLabel
                                    control={<Switch {...register('HasPlanOnDemand')} name="HasPlanOnDemand" checked={watch('HasPlanOnDemand')} />}
                                    label="Is EOM Only?"
                                />
                                <FormControlLabel
                                    control={<Switch {...register('HasForiegnCurrency')} name="HasForiegnCurrency" checked={watch('HasForiegnCurrency')} />}
                                    label="Has Foreign Currency"
                                />
                            </div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Year Founded"
                                    views={['year']}
                                    inputFormat="yyyy"
                                    format="yyyy-mm-dd"
                                    value={watch('CompanyFounded')}
                                    onChange={(date) => setValue('CompanyFounded', date)}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            fullWidth
                                            variant="standard"
                                            size="small"
                                            sx={{ mr: 1 }}
                                        />
                                    }
                                />
                            </LocalizationProvider>
                            <TextField
                                id="auth-key"
                                label="RO Auth Key"
                                variant="standard"
                                fullWidth
                                {...register('AuthKey')}
                                sx={cssInJs.textInput}
                            />
                        </div>

                        <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}>
                            Acquisition details
                        </Typography>
                        <div>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Referred By
                            </InputLabel>
                            <NativeSelect
                                variant="standard"
                                sx={cssInJs.select}
                                fullWidth
                                {...register('ReferredBy')}
                            >
                                <option value={''}>Select</option>
                            </NativeSelect>
                        </div>

                        <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}>
                            Notes
                        </Typography>
                        <div>
                            <TextField
                                id="notes"
                                label="General notes"
                                variant="standard"
                                fullWidth
                                multiline
                                rows={4}
                                {...register('Notes')}
                                sx={cssInJs.textInput}
                            />
                            <TextField
                                id="competitionNotes"
                                label="Competition notes"
                                variant="standard"
                                fullWidth
                                multiline
                                rows={4}
                                {...register('CompetitionNotes')}
                                sx={cssInJs.textInput}
                            />
                        </div>

                        <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}>
                            Commercial
                        </Typography>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Active Date"
                                    inputFormat="MMM dd, yyyy"
                                    format="MM/dd/yyyy"
                                    onChange={(date) => setValue('ActiveDate', date)}
                                    value={watch('ActiveDate')}
                                    renderInput={(params) => <TextField {...params}
                                                                        fullWidth
                                                                        variant="standard"
                                                                        size="small"
                                                                        error={!!errors.ActiveDate}
                                                                        helperText={errors.ActiveDate?.message}
                                                                        sx={{mt: 2}}
                                                                        {...register('ActiveDate', {required: 'Active date is required'})}
                                    />}
                                />
                            </LocalizationProvider>
                            <Controller
                                control={control}
                                name='BillingStartDate'
                                render={({field: {onChange, value}}) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label="Billing Start Date"
                                            onChange={onChange}
                                            value={value}
                                            renderInput={(params) => <TextField {...params}
                                                                                fullWidth
                                                                                variant="standard"
                                                                                size="small"
                                                                                sx={{mt: 2}}
                                            />}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                        </div>

                        <div
                            style={{
                                display: 'block'
                            }}
                        >
                            <hr style={{border: '1px solid #8b65ac', margin: '25px 0'}}/>

                            <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}>
                                Integrations
                            </Typography>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div style={{display: 'flex'}}>
                                    <FormControlLabel
                                        control={<Switch {...register('HasShuttle')} name="HasShuttle" checked={watch('HasShuttle')} onChange={handleHasShuttleChange} />}
                                        label="Shuttle Integration"
                                    />
                                    {hasShuttle ? <TextField
                                        id="ShuttleRate"
                                        label="Shuttle Rate"
                                        variant="standard"
                                        {...register('ShuttleRate')}
                                        sx={{...cssInJs.textInput, width: '20%'}}
                                        required
                                    /> : null}
                                </div>
                                <div style={{display: 'flex'}}>
                                    <FormControlLabel
                                        control={
                                            <Switch {...register('HasHeadCount')} checked={watch('HasHeadCount')} onChange={handleHasHeadCountChange}
                                                    name="HasHeadCount"/>
                                        }
                                        label="Headcount Integration"
                                    />
                                    {hasHeadCount ? <TextField
                                        id="HeadCountRate"
                                        label="HeadCount Rate"
                                        variant="standard"
                                        {...register('HeadCountRate')}
                                        sx={{...cssInJs.textInput, width: '20%'}}
                                    /> : null}
                                </div>
                            </div>

                            <hr style={{border: '1px solid #8b65ac', margin: '25px 0'}}/>

                            <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}>
                                Admin
                            </Typography>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <FormControlLabel
                                    control={<Switch {...register('IsManualEntry')} name="IsManualEntry" checked={watch('IsManualEntry')} />}
                                    label="Is Manual Entry?"
                                />
                                <FormControlLabel
                                    control={<Switch {...register('IsTest')} name="IsTest" checked={watch('IsTest')} />}
                                    label="Is Demo?"
                                />
                                <FormControlLabel
                                    control={<Switch {...register('IsBeta')} name="IsBeta" checked={watch('IsBeta')} />}
                                    label="Is Hiatus?"
                                />
                                <FormControlLabel
                                    control={<Switch {...register('IsVip')} name="IsVip" checked={watch('IsVip')} />}
                                    label="Is VIP?"
                                />
                                {/* <FormControlLabel
                                    control={<Switch {...register('HasSharedCustom')} name="HasSharedCustom" checked={watch('HasSharedCustom')} />}
                                    label="Uses shared custom export?"
                                />
                                <FormControlLabel
                                    control={<Switch {...register('HasFaves')} name="HasFaves" checked={watch('HasFaves')} />}
                                    label="Uses Faves Export?"
                                /> */}

                            </div>

                            <hr style={{border: '1px solid #8b65ac', margin: '25px 0'}}/>

                            <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}>
                                POS API Settings
                            </Typography>
                            <div>
                                <TextField
                                    id="ApiKey"
                                    label="POS API Key"
                                    variant="standard"
                                    fullWidth
                                    {...register('ApiKey')}
                                    sx={cssInJs.textInput}
                                />
                                <TextField
                                    id="ApiUrl"
                                    label="POS API URL"
                                    variant="standard"
                                    fullWidth
                                    {...register('ApiUrl')}
                                    sx={cssInJs.textInput}
                                />
                                <TextField
                                    id="ApiField"
                                    label="POS API Field"
                                    variant="standard"
                                    fullWidth
                                    {...register('ApiField')}
                                    sx={cssInJs.textInput}
                                />
                            </div>

                            <hr style={{border: '1px solid #8b65ac', margin: '25px 0'}}/>

                            <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}>
                                Processing Rules
                            </Typography>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <FormControlLabel
                                    control={<Switch {...register('DataProcessingException.DoAutomaticFinalizations')}
                                                     name="DataProcessingException.DoAutomaticFinalizations" checked={watch('DataProcessingException.DoAutomaticFinalizations')} />}
                                    label="Finalize automatically?"
                                />
                                <FormControlLabel
                                    control={<Switch {...register('DataProcessingException.DoNegativeAdjustments')}
                                                     name="DataProcessingException.DoNegativeAdjustments" checked={watch('DataProcessingException.DoNegativeAdjustments')} />}
                                    label="Do negative adjustments?"
                                />
                                <FormControlLabel
                                    control={<Switch {...register('DataProcessingException.HasMarkdownInventoryAdjustments')}
                                                     name="DataProcessingException.HasMarkdownInventoryAdjustments" checked={watch('DataProcessingException.HasMarkdownInventoryAdjustments')} />}
                                    label="Has Markdown Inventory Adjustments?"
                                />
                                <FormControlLabel
                                    control={<Switch {...register('DataProcessingException.DoPerpetuateInventory')}
                                                     name="DataProcessingException.DoPerpetuateInventory" checked={watch('DataProcessingException.DoPerpetuateInventory')} />}
                                    label="Perpetuate Inventory?"
                                />
                                <FormControlLabel
                                    control={<Switch {...register('DataProcessingException.PeriodFromTransactionDate')}
                                                     name="DataProcessingException.PeriodFromTransactionDate" checked={watch('DataProcessingException.PeriodFromTransactionDate')} />}
                                    label="SkuData Period from Transaction Date?"
                                />
                                <TextField
                                    id="periodOffset"
                                    label="Sku Data Period Offset"
                                    variant="standard"
                                    fullWidth
                                    {...register('periodOffset')}
                                    sx={cssInJs.textInput}
                                />
                            </div>
                            <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}
                                        onClick={toggleClientStatusModal}>
                                Status
                            </Typography>
                            <ClientStatusModal open={isClientStatusModalVisible} onClose={toggleClientStatusModal}
                                               accountState={selectedAccountStatusValue}/>
                        </div>

                        <Button
                            variant="contained"
                            type="submit"
                        >
                            Save Client Profile
                        </Button>
                    </form>
                </FormProvider>}
            </CardContent>
        </Card>
    )
}

export default GeneralClientEditForm
