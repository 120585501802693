import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Box,
	Typography,
	Grid,
	IconButton,
	Paper,
	Switch,
	CircularProgress,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	Chip,
	Menu,
	MenuItem,
	Fade,
	Collapse,
	Alert,
	TextField,
} from '@mui/material';
import {
	LoadingButton
} from '@mui/lab'
import SectionTitle from '../../../components/common/SectionTitle'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import LocationService from '../../../services/LocationService'
import LabelService from '../../../services/LabelService'
import PosService from '../../../services/PosService'
import moment from 'moment'
import ClearIcon from '@mui/icons-material/Clear';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, DatePicker } from '@mui/x-date-pickers';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import TagService from "../../../services/TagService";


const jss = {
	tableLabel: {
		fontWeight: 'bolder',
		width: '25%',
		textAlign: 'right'
	},
	editTitle: {
		fontSize: 16,
		fontWeight: 'bolder',
		mt: 3,
	},
	tagSection: {
		'.MuiChip-root': {
			mr:1,
			mt:1,
		}
	}
}


function ClientLocationEdit(props) {
	const { location, isAdmin } = props
	const [editing, setEditing] = useState(false)
	const [saving, setSaving] = useState(false)
	const [has_change, setHasChange] = useState(false)
	const [open_more, setOpenMore] = useState(false)
	const more_element = useRef()
	const [form_data, setFormData] = useState()
	// const [labelData, setLabelData] = useState()
	const [tagData, setTagData] = useState()
	const [pos, setPos] = useState([])

	useEffect(() => {
		if( location ) {
			setEditing(false)
			setHasChange(false)
			// loadLabels()
			loadTags()
			loadPos()
			setFormData({
				location_code: location.location_code,
				location_name: location.location_name,
				tags: location.tags,
				address: location.address,
				address2: location.address2,
				city: location.city,
				state: location.state,
				zipcode: location.zipcode,
				province: location.province,
				country: location.country,
				phone: location.phone,
				fax: location.fax,
				pos_id: location.pos_id,
				fiscal_year_type: location.fiscal_year_type,
				fiscal_year_date: location.fiscal_year_date,
				breakeven: location.breakeven,
				imu: location.imu,
				active_date: location.active_date,
				billing_start_date: location.billing_start_date,
				setup_billed_date: location.setup_billed_date,
			})
		}
	}, [location])

	// async function loadLabels() {
	// 	const data = await LabelService.getLabels()
	// 	setLabelData(data)
	// }

	async function loadTags() {
		const data = await TagService.getTagGroups()
		setTagData(data)
		// let tags = []
		// data.forEach(item => {
		// 	tags.push(...item.tags)
		// })
		// console.log(tags)
	}

	async function loadPos() {
		let _pos = await PosService.getPos()
		setPos(_pos)
	}

	async function undeleteDate() {
		try {
			await LocationService.undeleteById(location.clientid, location.client_location_id)
			setOpenMore(false)
			props.onSave({
				...location,
				drop_date: null
			})
		} catch(e) {
			console.log(e)
		}
	}

	async function deleteData() {
		try {
			await LocationService.deleteById(location.clientid, location.client_location_id)
			setOpenMore(false)
			props.onSave({
				...location,
				drop_date: moment().format('YYYY-MM-DD HH:mm:ss')
			})
		} catch(e) {
			console.log(e)
		}
	}

	const common_input_props = {
		fullWidth: true, 
		variant: "outlined",
		size: "small",
		onChange: e => updateFormData(e),
		sx: {mt:2},
	}

	function updateFormData(e) {
		setFormData({
			...form_data,
			[e.target.name]: e.target.value
		})
		if( !has_change ) {
			setHasChange(true)
		}
	}

	function handleDeleteTag(label_id) {
		const _tags = form_data.tags.filter(item => {
			return item.id!=label_id
		})
		updateFormData({
			target:{
				name:'tags',
				value:_tags
			},
		})
	}

	// function updateLabel(e) {
	// 	const _tags = form_data.tags
	// 	_tags.push(e.target.value)
	// 	updateFormData({
	// 		target:{
	// 			name:'tags',
	// 			value:_tags
	// 		},
	// 	})
	// }

	function updateTag(e) {
		const _tags = form_data.tags
		_tags.push(e.target.value)
		updateFormData({
			target:{
				name:'tags',
				value:_tags
			},
		})
	}

	async function saveLocation() {
		try {
			setSaving(true)
			const _location = await LocationService.updateLocation(location.clientid, location.client_location_id, form_data)
			setEditing(false)
			setHasChange(false)
			if( props.onSave ) {
				props.onSave(_location)	
			}
		} catch(e) {
			console.log(e.response?.data.message)
		}
		setSaving(false)
	}

	if( location ) {
		return <Paper elevation={2}>
			<SectionTitle
				label={location.location_name}
				subLabel="Location profile"
				type="primary"
				rightComponent={
					isAdmin ? 
						<>
							{!editing && 
								<IconButton onClick={e => setEditing(true)}>
									<ModeEditOutlineOutlinedIcon sx={{color: 'white'}}/>
								</IconButton>
							}
							{editing && 
								<IconButton onClick={e => setEditing(false)}>
									<KeyboardArrowUpOutlinedIcon sx={{color: 'white'}}/>
								</IconButton>
							}
							<IconButton onClick={e => {setOpenMore(true)}} ref={more_element}>
								<MoreVertOutlinedIcon sx={{color: 'white'}} />
							</IconButton>
							<Menu
								open={open_more}
								anchorEl={more_element.current}
								onClose={e => {setOpenMore(false)}}
							>
								{location.deleted_at && 
									<MenuItem dense onClick={undeleteDate}>Undelete Location</MenuItem>
								}
								{!location.deleted_at && 
									<MenuItem dense onClick={deleteData}>Drop Location</MenuItem>
								}
							</Menu>
						</>
					: null}
			/>
			<Box sx={{p:2}}>
				{!editing && 
					<TableContainer>
						<Table>
							<TableBody>
								{location.deleted_at && <TableRow>
									<TableCell sx={jss.tableLabel}>Dropped</TableCell>
									<TableCell sx={{color: 'error.main', fontWeight: 'bold'}}>{moment(location.deleted_at).format('MM-DD-YYYY')}</TableCell>
								</TableRow>}
								{(location.title
								|| location.address
								|| location.address2
								|| location.city
								|| location.state
								|| location.zipcode
								|| location.province
								|| location.country) && 
								<TableRow>
									<TableCell sx={jss.tableLabel}>Address</TableCell>
									<TableCell>
										{location.address && <>{location.address} <br /></>}
										{location.address2 && <>{location.address2} <br /></>}
										{location.city && <>{location.city} <br /></>}
										{location.state && <>{location.state} <br /></>}
										{location.zipcode && <>{location.zipcode} <br /></>}
										{location.province && <>{location.province} <br /></>}
										{location.country && <>{location.country} <br /></>}
									</TableCell>
								</TableRow>
								}
								{location.phone && <TableRow>
									<TableCell sx={jss.tableLabel}>Phone</TableCell>
									<TableCell>{location.phone}</TableCell>
								</TableRow>}
								{location.fax && <TableRow>
									<TableCell sx={jss.tableLabel}>Fax</TableCell>
									<TableCell>{location.fax}</TableCell>
								</TableRow>}
								{location.pos && <TableRow>
									<TableCell sx={jss.tableLabel}>POS</TableCell>
									<TableCell>{location.pos.name}</TableCell>
								</TableRow>}
			                    {location.fiscal_year_date && <TableRow>
									<TableCell sx={jss.tableLabel}>Fiscal Year End</TableCell>
									<TableCell>{location.fiscal_year_date}</TableCell>
			                    </TableRow>}
			                    {location.imu && <TableRow>
									<TableCell sx={jss.tableLabel}>IMU</TableCell>
									<TableCell>{location.imu}</TableCell>
			                    </TableRow>}
			                    {location.active_date && <TableRow>
									<TableCell sx={jss.tableLabel}>Active date</TableCell>
									<TableCell>{moment(location.active_date).format('MMM DD YYYY')}</TableCell>
			                    </TableRow>}
			                    {location.billing_start_date && <TableRow>
									<TableCell sx={jss.tableLabel}>Billing start date</TableCell>
									<TableCell>{moment(location.billing_start_date).format('MMM DD YYYY')}</TableCell>
			                    </TableRow>}
			                    {location.tags && <TableRow>
									<TableCell sx={jss.tableLabel}>Tags</TableCell>
									<TableCell>{location.tags.map((item, index) => {
										return <Chip key={`location_tag_${index}`} label={item.name} size="small" sx={{mb:0.5,mr:0.5}}/>
									})}</TableCell>
			                    </TableRow>}
			                    {location.breakeven && <TableRow>
									<TableCell sx={jss.tableLabel}>Breakeven</TableCell>
									<TableCell>{location.breakeven}</TableCell>
			                    </TableRow>}
							</TableBody>
						</Table>
					</TableContainer>
				}
				{editing && 
					<Fade timeout={500} in>
						<Box>
							<Collapse in={has_change}>
								<Alert sx={{mt:2}} severity="warning">You have unsaved changes, please hit save before continuing.</Alert>
							</Collapse>

							<Box sx={{...jss.editTitle, mt:0}}>General</Box>
							<TextField 
								{...common_input_props}
								label="Code" 
								name="location_code"
								value={form_data.location_code || ''}
							/>
							<TextField 
								{...common_input_props}
								label="Name" 
								name="location_name"
								value={form_data.location_name || ''}
							/>

							{(form_data?.tags && tagData) &&
								<>
									<Box sx={jss.editTitle}>Tags</Box>
									<Box sx={jss.tagSection}>
									{form_data.tags.map(_tag => {
										return <Chip key={`location_edit_tag_${_tag.id}`} label={_tag.name} onDelete={e=>handleDeleteTag(_tag.id)} deleteIcon={<ClearIcon />}/>
									})}
									</Box>
									{
										// TODO: Fix this. _g.name is undefined and throws a lot of warnings in console
										tagData.map(_g => {
											return <>
												<TextField
													{...common_input_props}
													label={_g.name + ' Tags'}
													name="_g.name"
													select
													onChange={e => updateTag(e)}
												>
												{_g.tags.map(item => {
													return <MenuItem key={`tag_${item.id}`} value={item}>{item.name}</MenuItem>
												})}
												</TextField>
											</>
										})
									}
								</>
							}

							<Box sx={jss.editTitle}>Address</Box>
							<TextField 
								{...common_input_props}
								label="Address 1" 
								name="address"
								value={form_data.address || ''}
							/>
							<TextField 
								{...common_input_props}
								label="Address 2" 
								name="address2"
								value={form_data.address2 || ''}
							/>
							<TextField
								{...common_input_props}
								label="City"
								name="city"
								value={form_data.city || ""}
							/>
							<TextField
								{...common_input_props}
								label="State"
								name="state"
								value={form_data.state || ""}
							/>
							<TextField
								{...common_input_props}
								label="ZipCode"
								name="zipcode"
								value={form_data.zipcode || ""}
							/>
							<TextField
								{...common_input_props}
								label="Province"
								name="province"
								value={form_data.province || ""}
							/>
							<TextField
								{...common_input_props}
								label="Country"
								name="country"
								value={form_data.country || ""}
							/>

							<Box sx={jss.editTitle}>Contact Detail</Box>
							<TextField
								{...common_input_props}
								label="Phone"
								name="phone"
								value={form_data.phone || ""}
							/>
							<TextField
								{...common_input_props}
								label="Fax"
								name="fax"
								value={form_data.fax || ""}
							/>

							<Box sx={jss.editTitle}>Technical</Box>
							<TextField
								{...common_input_props}
								label="POS System"
								name="pos_id"
								value={form_data.pos_id || ""}
								select
							>
							{
								pos.map(item => {
									if( item.name ) {
										return <MenuItem key={`pos_option_${item.id}`} value={item.id}>{item.name}</MenuItem>	
									}
								})
							}
							</TextField>
							<TextField
								{...common_input_props}
								label="Fiscal Year Type"
								name="fiscal_year_type"
								value={form_data.fiscal_year_type || ""}
								select
							>
								<MenuItem value='calendar'>Calendar</MenuItem>
								<MenuItem value='4-5-4'>4-5-4</MenuItem>
								<MenuItem value='4-4-5'>4-4-5</MenuItem>
							</TextField>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DesktopDatePicker
									label="Fiscal Year End"
									value={form_data.fiscal_year_date}
									inputFormat="MMM dd"
									mask=""
									onChange={e => updateFormData({
										target: {
											value: moment(e).format("YYYY-MM-DD HH:mm:ss"),
											name: 'fiscal_year_date'
										}
									})}
									renderInput={(params) => <TextField {...params}
										fullWidth={true}
										variant="outlined"
										size="small"
										sx={{mt:2}} 
									/>}
								/>
							</LocalizationProvider>
							<TextField
								{...common_input_props}
								label="Breakeven"
								name="breakeven"
								value={form_data.breakeven || ""}
								type="number"
							/>
							<TextField
								{...common_input_props}
								label="Default Imu"
								name="imu"
								value={form_data.imu || ""}
								type="number"
							/>

							<Box sx={jss.editTitle}>Commercial</Box>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DesktopDatePicker
									label="Active Date"
									value={form_data.active_date}
									inputFormat="MMM dd yyyy"
									mask=""
									onChange={e => updateFormData({
										target: {
											value: moment(e).format("YYYY-MM-DD HH:mm:ss"),
											name: 'active_date'
										}
									})}
									renderInput={(params) => <TextField {...params}
										fullWidth={true}
										variant="outlined"
										size="small"
										sx={{mt:2}} 
									/>}
								/>
							</LocalizationProvider>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DesktopDatePicker
									label="Billing Start Date"
									value={form_data.billing_start_date}
									inputFormat="MMM dd yyyy"
									mask=""
									onChange={e => updateFormData({
										target: {
											value: moment(e).format("YYYY-MM-DD HH:mm:ss"),
											name: 'billing_start_date'
										}
									})}
									renderInput={(params) => <TextField {...params}
										fullWidth={true}
										variant="outlined"
										size="small"
										sx={{mt:2}} 
									/>}
								/>
							</LocalizationProvider>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DesktopDatePicker
									label="Setup Billed Date"
									value={form_data.setup_billed_date}
									inputFormat="MMM dd yyyy"
									mask=""
									onChange={e => updateFormData({
										target: {
											value: moment(e).format("YYYY-MM-DD HH:mm:ss"),
											name: 'setup_billed_date'
										}
									})}
									renderInput={(params) => <TextField {...params}
										fullWidth={true}
										variant="outlined"
										size="small"
										sx={{mt:2}} 
									/>}
								/>
							</LocalizationProvider>

							<Box sx={{mt:2}}>
								<LoadingButton loading={saving} size="large" variant="contained" onClick={saveLocation} startIcon={<SaveTwoToneIcon />}>
									Save
								</LoadingButton>
							</Box>

						</Box>
					</Fade>
				}
			</Box>
		</Paper>
	} else {
		return <></>
	}
}
export default ClientLocationEdit