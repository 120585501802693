import React, {useState, useRef, useEffect} from 'react';
import ModifyStyle from '../../components/plan/PlanStyle';
import ClientService from '../../services/ClientService';
import PlanService from '../../services/PlanService';
import CurrentClientContext from '../../providers/CurrentClientProvider';
import MessageContext from '../../providers/GlobalTip';
import {
    TextField,
    Grid,
    Button,
    Box,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, 
    Chip, 
    Paper,
    Backdrop,
    CircularProgress,
    ToggleButtonGroup,
    ToggleButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    ButtonGroup,
} from '@mui/material';
import {
	LoadingButton
} from '@mui/lab'
import { 
	BasePlanAnnualForm, 
	BasePlanTableData, 
	colorMap, 
	BasePlanTableRow, 
	BasePlanTableDataMap
} from '../../models/BasePlanModel';
import cloneDeep from 'lodash.clonedeep';
import _ from 'underscore';
import numeral from 'numeral';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import PlanCalculatorService from '../../services/PlanCalculatorService'
import BigTable from '../../components/plan/BigTable';
import moment from 'moment';
import PlanCopyToModal from './PlanCopyToModal';
import useConfirm from '../../providers/ConfirmDialog';
import PlanCopyFromModal from './PlanCopyFromModal';

function BasePlanEdit(props) {

	const {
		clientBasicData,
		optionValue,
		onOptionValueChange,
		client
	} = props
	const [loading, setLoading] = useState(true);
	const [loadError, setLoadError] = useState()
    const [planData, setPlanData] = useState()
	const [salesModelType, setSalesModelType] = useState(0)
    const [imuModelType, setImuModelType] = useState(0)
    let [annualForm, setAnnualForm] = useState({})
    const [displayMonths, setDisplayMonths] = useState([])
    let [tableData, setTableData] = useState(new Map())
    const currentUpdateRef = useRef(null);
    const [singleSaving, setSingleSaving] = useState(false)
    const [blukSaving, setBlukSaving] = useState(false)
    const messageContext = MessageContext.useMessage()
    const [buildingAll, setBuildingAll] = useState(false)
    const [buildingAllLocation, setBuildingAllLocation] = useState(false)
    const [loadingHistoricalData, setLoadingHistoricalData] = useState(false)
    const [historicalPeriodDatas, setHistoricalPeriodDatas] = useState([])
    const [displayCopyToModal, setDisplayCopyToModal] = useState(false);
    const [displayCopyFromModal, setDisplayCopyFromModal] = useState(false);
    const [copyFromLoading, setCopyFromLoading] = useState(false);
    const triggerRecalculate = useRef(false)
    const confirm = useConfirm();

    useEffect(() => {
    	loadLocationClassPlanBaseData()
    }, [clientBasicData])

    useEffect(() => {
        if( planData ) {
            initPlanData()
        }
    }, [planData])

    useEffect(() => {
        if (tableData && triggerRecalculate.current) {
            recalculateAllData();
        }
    }, [tableData]);

    const initPlanData = () => {
    	const _annualForm = cloneDeep(BasePlanAnnualForm)
        initAnnualForm(_annualForm)
        setAnnualForm(_annualForm)
        const _display_months = planData.display_months.map(item => {
            const [month, year] = item.monthDisplay.split(' ');
            return ({...item, month, year})
        });
        const basePlanTableData = cloneDeep(BasePlanTableDataMap);
		planData.actualdetails[0].InventoryTotal_PrevMon = parseFloat(planData.planning_month_actual_inv_bom)
        for(const [key, data] of basePlanTableData) {
          if(data instanceof Map) {
            for(const [_, val] of data) {
                val.innerData = planData[val.sourceKey].map(item => {
                    const value = +(item[val.key] ?? 0);
                    return {
                        value: value,
                        orig: value,
                        current: value
                    }
                })
            }
          }
        }
        setTableData(basePlanTableData)
        setDisplayMonths(_display_months)
        setSalesModelType(planData.SalesModelType)
        setImuModelType(planData.ImuModelType)
    }

    const loadLocationClassPlanBaseData = async(locationClassId=null) => {
    	try {
    		setLoading(true)
	        const data = await PlanService.getLocationClassBasePlanDetail(client.Id, locationClassId ? locationClassId : clientBasicData.current_location_class.locationclassid)
	        setPlanData(data)
            PlanCalculatorService._planData = data
            setLoadError(null)
    	} catch(e) {
    		setLoadError(e.response?.data?.message)
    	}
        setLoading(false)
    }

    const loadLocationClassPlanHistoryData = async(location_class_id = null) => {
    	try {
    		setLoading(true)
	        const data = await PlanService.getLocationClassHistoryPlanDetail(client.Id, location_class_id ?? clientBasicData.current_location_class.locationclassid)
	        setPlanData(data)
            setLoadError(null)
    	} catch(e) {
    		setLoadError(e.response?.data?.message)
    	}
        setLoading(false)
    }

    const initAnnualForm = (form = {}) => {
        for(const key in form) {
            if(planData.hasOwnProperty(key)) {
                const value = +planData[key];
                form[key].value = value;
                form[key].current = value;
                form[key].orig = value;
                if(form[key].chipProps) {
                    initAnnualForm(form[key].chipProps)
                }
            }
        }
    }

    const removeCommas = (str) => {
        while (str?.search(",") >= 0) {
            str = (str + "").replace(',', '');
        }
        return str || '0';
    }

    const numberWithCommas = (num) => {
        if(!isNaN(num)){
            num = parseInt(num);
         return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return '0';
    }

    const updateAnnualForm = (key, updateParams) => {
        const item = annualForm[key];
        setAnnualForm({
            ...annualForm,
            [key]: {...item, ...updateParams}
        })
    }

    const changeAnnualSales = (params, form) => {
        const smt = parseFloat(params.smt);
        let annualSalesAmt = 0;
        switch(smt) {
            case 0:
                annualSalesAmt = params.value;
                break;
            case 1:
            case 2:
            case 3:
            case 4:
                annualSalesAmt = params.current;
                break;
        };
        const _annualForm = form ?? cloneDeep(annualForm);
        const _tableData = cloneDeep(tableData);
        const previousAnnualSalesAmt = _annualForm.annual_sales.current;
        if(annualSalesAmt != previousAnnualSalesAmt) {
            _annualForm.annual_sales.value = annualSalesAmt;
            _annualForm.annual_sales.current = annualSalesAmt;
            const annualMarkdownAmt = _annualForm.annual_markdowns.value;
            const md_per = annualMarkdownAmt / 100;
            const an_sales = annualSalesAmt;
            const md_amt = an_sales * md_per;
            const markdownsPercentPlan = _tableData.get('Markdowns').get('MarkdownsPercentPlan');
            const markdownsPlan = _tableData.get('Markdowns').get('MarkdownsPlan');
            const stockToSalesPlan = _tableData.get('Inventory').get('StockToSalesPlan');
            const inventoryBomPlan = _tableData.get('Inventory').get('InventoryBomPlan');
            // updateMarkdownsPlan
            markdownsPlan.innerData = markdownsPlan.innerData.map((val, index) => {
                const value = +(md_amt * (markdownsPercentPlan.innerData[index].value / 100)).toFixed(0)
                return {...val, value}
            })
            // updateSalesPlan
            const salesPercentPlan = _tableData.get('Sales').get('SalesPercentPlan');
            const salesPlan = _tableData.get('Sales').get('SalesPlan');
            salesPlan.innerData = salesPlan.innerData.map((val, index) => {
                const value = +(an_sales * salesPercentPlan.innerData[index].value / 100).toFixed(0)
                return {...val, value}
            })
            // updateInventoryBomPlan
            inventoryBomPlan.innerData = inventoryBomPlan.innerData.map((val, index) => {
                const sales = salesPlan.innerData[index].value;
                const ss =  stockToSalesPlan.innerData[index].value;
                const inv = +(sales * ss).toFixed(0)
                return {...val, value: inv, current: inv}
            })
            PlanCalculatorService.update13thMonth(_tableData);
            PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
            PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
            PlanCalculatorService.balanceInflow(_tableData, _annualForm);
            setTableData(_tableData);
            setAnnualForm(_annualForm)
            setSalesModelType(smt)
        }
    }

    const changeAnnualMarkdowns = (params) => {
        const annualMarkdownAmt = params.value;
        const _annualForm = cloneDeep(annualForm);
        const _tableData = cloneDeep(tableData);
        const previousMarkdownAmt = _annualForm.annual_markdowns.current;
        if(annualMarkdownAmt != previousMarkdownAmt) {
            _annualForm.annual_markdowns.value = annualMarkdownAmt;
            _annualForm.annual_markdowns.current = annualMarkdownAmt;
            const md_per = annualMarkdownAmt / 100;
            const an_sales = _annualForm.annual_sales.value;
            const md_amt = an_sales * md_per;
            const markdownsPercentPlan = _tableData.get('Markdowns').get('MarkdownsPercentPlan');
            const markdownsPlan = _tableData.get('Markdowns').get('MarkdownsPlan');
            markdownsPlan.innerData = markdownsPlan.innerData.map((val, index) => {
                const md_per_plan = markdownsPercentPlan.innerData[index].value;
                const new_md_plan = +(md_amt * (md_per_plan / 100)).toFixed(0);
                return {...val, value: new_md_plan}
            });
            PlanCalculatorService.updateInflowRetail(_tableData, _annualForm)
            PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
            setTableData(_tableData)
            setAnnualForm(_annualForm)
        }
    }

    const changeAnnualTurn = (params) => {
        const _annualForm = cloneDeep(annualForm);
        const _tableData = cloneDeep(tableData);
        const turnAmt = params.value;
        const previousTurn = _annualForm.turn.current;
        if(turnAmt != previousTurn) {
            _annualForm.turn.current = turnAmt;
            if(turnAmt == 0 || turnAmt == '') {
                const inventoryBomPlan = _tableData.get('Inventory').get('InventoryBomPlan');
                const stockToSalesPlan = _tableData.get('Inventory').get('StockToSalesPlan');
                inventoryBomPlan.innerData = inventoryBomPlan.innerData.map((val, index) => ({...val, value:0, current: 0}))
                stockToSalesPlan.innerData = stockToSalesPlan.innerData.map((val, index) => ({...val, value: 0, current: 0}))
                PlanCalculatorService.update13thMonth(_tableData);
                PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
                PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
                PlanCalculatorService.updateTurn(_tableData, _annualForm)
                setTableData(_tableData)
            }else if (turnAmt < 0) {
                _annualForm.turn.value = previousTurn;
            }else {
                _annualForm.turn.value = turnAmt;
                const inventoryBomPlan = _tableData.get('Inventory').get('InventoryBomPlan');
                const annualSalesAmt = _annualForm.annual_sales.value;
                const avgInv = annualSalesAmt / turnAmt;
                let origAvgInv = 0;
                if(previousTurn > 0){
                    origAvgInv = annualSalesAmt / previousTurn;
                } 
                let turnAdd = false;
                if(previousTurn > turnAmt || previousTurn == 0){
                    turnAdd = true;
                }
                let avgMonInvChange = origAvgInv - avgInv;
                let nonZeroCnt = 0;
                let zeroInv = 0;
                do {
                    nonZeroCnt = 0;
                    if(zeroInv > 0) {
                        inventoryBomPlan.innerData.forEach((val, index) => {
                            if(val.value > 0) {
                                nonZeroCnt = nonZeroCnt + 1;
                            }
                        })
                        avgMonInvChange = zeroInv / nonZeroCnt;
                        zeroInv = 0
                    }
                    const inventoryBomPlanInnerData = inventoryBomPlan.innerData;
                    inventoryBomPlanInnerData.forEach((val, index) => {
                        const origValue = val.value;
                        if(origValue > 0 || previousTurn == 0 && index < 12) {
                            let newVal = 0;
                            if(turnAdd) {
                                newVal = origValue + Math.abs(avgMonInvChange);
                            }else {
                                newVal = origValue - Math.abs(avgMonInvChange);
                                if(newVal < 0) {
                                    zeroInv = zeroInv + Math.abs(newVal);
                                    nonZeroCnt = 1;
                                    newVal = 0
                                }
                            }
                            const value = (newVal).toFixed(0);
                            inventoryBomPlanInnerData[index].value = +value;
                            inventoryBomPlanInnerData[index].current = +value;
                        }
                    })
                    inventoryBomPlan.innerData = inventoryBomPlanInnerData;
                }while(nonZeroCnt > 0 && zeroInv > 0);
                PlanCalculatorService.updateStockToSales(_tableData);
                PlanCalculatorService.update13thMonth(_tableData);
                PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
                PlanCalculatorService.updateTurn(_tableData, _annualForm)
                setTableData(_tableData)
            }
            setAnnualForm(_annualForm);
        }
    }

    const changeAnnualImu = (params) => {
        const _annualForm = cloneDeep(annualForm);
        const imt = parseFloat(params.imt);
        let imuAmt = 0;
        switch(imt){
            case 0:
                imuAmt = params.value;
                break;
            case 1:
            case 2:
                imuAmt = params.current;
                break;
        }
        const previousImu = _annualForm.imu.current;
        if(imuAmt != previousImu) {
            const _tableData = cloneDeep(tableData);
            _annualForm.imu.value = imuAmt;
            PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
            setAnnualForm(_annualForm);
            setTableData(_tableData);
        }

    }

    const getKFlow = () => {
        const _tableData = cloneDeep(tableData);
        const _annualForm = cloneDeep(annualForm);
        PlanCalculatorService.getKFlow(_tableData, _annualForm)
        setTableData(_tableData);
        setAnnualForm(_annualForm)
    }

    const onAnnualFormBlur = (key, params) => {
        currentUpdateRef.current = key
        if(key === 'annual_sales') {
            changeAnnualSales(params)
        }else if(key === 'annual_markdowns') {
            changeAnnualMarkdowns(params)
        }else if(key === 'turn') {
            changeAnnualTurn(params)
        }else if(key === 'imu') {
            changeAnnualImu(params)
        }
    }

    const updateTableForm = (e, key, innerKey, colIndex) => {
        const _tableData = cloneDeep(tableData);
        const value = e.target.value;
        _tableData.get(key).get(innerKey).innerData[colIndex].value = value;
        setTableData(_tableData)
    }

    const onBuildAll = async(type) => {
        const choice = await confirm({ 
            description: "Are you sure to build?"
        });
        if (choice) { 
            try {
                if( type == 1 ) {
                    setBuildingAllLocation(true)
                } else if( type == 2 ) {
                    setBuildingAll(true)
                }
                await PlanService.bulkSaveLocationClassBasePlan(client.Id, clientBasicData.current_location_class.id, {
                    build_type: type
                })
                messageContext.showSuccess('Build successfully')
            } catch(e) {
                messageContext.show(e.response?.data?.message)
            }
            setBuildingAllLocation(false)
            setBuildingAll(false)
        }
    }

    const onBlurTableForm = (tableKey, rowKey, colIndex) => {
        if(rowKey === 'SalesPercentPlan') {
            salesUpdate(tableKey, rowKey, colIndex)
        }else if(rowKey === 'StockToSalesPlan') {
            stocktosalesplanUpdate(tableKey, rowKey, colIndex)
        }else if(rowKey === 'InventoryBomPlan') {
            inventoryUpdate(tableKey, rowKey, colIndex)
        }else if(rowKey === 'MarkdownsPercentPlan') {
            markdownsUpdate(tableKey, rowKey, colIndex)
        }
    }

    const salesUpdate = (tableKey, rowKey, colIndex, bulkUpdate=false) => {
        const _tableData = cloneDeep(tableData);
        const _annualForm = cloneDeep(annualForm); 
        const targetRow = _tableData.get(tableKey).get(rowKey);
        const targetRowInnerData = targetRow.innerData;
        const salesPlan = _tableData.get('Sales').get('SalesPlan');
        const salesPlanVsHistorical = _tableData.get('Sales').get('sales_plan_vs_historical').innerData;
        const salesPlanInnerData = salesPlan.innerData;
        const { value, current } = targetRowInnerData[colIndex];
        targetRowInnerData[colIndex].current = value;
        const annualSalesAmt = annualForm.annual_sales.value;
        salesPlanInnerData[colIndex].value = +(annualSalesAmt * value / 100).toFixed(0);
        salesPlanVsHistorical[colIndex].value = planData.plandetails[colIndex].avg_sales_plan ? (salesPlanInnerData[colIndex].value / planData.plandetails[colIndex].avg_sales_plan * 100).toFixed(2) : 0
        salesPlan.innerData = salesPlanInnerData;
        PlanCalculatorService.updateStockToSales(_tableData);
        PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
        PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
        updateCalcTrendButton(_tableData, _annualForm)
        updateCalcTrendV3Button(_tableData, _annualForm)
        if (!bulkUpdate) {
            setTableData(_tableData);
            setAnnualForm(_annualForm);
        } else {
            tableData = _tableData;
            annualForm = _annualForm;
        }
    }

    const updateCalcTrendButton = (tableData, form) => {
        let salespct = 0;
        let sales = 0;
        let count = 0;
        const salesPercentPlan = tableData.get('Sales').get('SalesPercentPlan');
        const salesPercentPlanInnerData = salesPercentPlan.innerData;
        [...salesPercentPlanInnerData].reverse().forEach((val, index) => {
            if(val.value >= 4 && count < 3) {
                count = count + 1;
                salespct = salespct + (val.value * 1);
                sales = sales + planData.actualdetails[index].Sales * 1;
            }
        })
        let calc_trend = +(sales / (salespct / 100)).toFixed(0)
        const annual_sales_calc_trend =  form.annual_sales.chipProps.annual_sales_calc_trend;
        annual_sales_calc_trend.value = calc_trend;
        annual_sales_calc_trend.current = calc_trend;
    }

    const updateCalcTrendV3Button = (tableData, form, futureTrend=false) => {
        const actualdetails = planData.actualdetails;
        const salesPercentPlan = tableData.get('Sales').get('SalesPlan');
        let data = [];
        let calc = [];
        
        let annual_markdown = parseFloat(form.annual_markdowns.value);
        let count = 1;
        for(let x = 11; x >= 0; x--){
            const salesPerPlan = salesPercentPlan.innerData[x].value;
            if(parseFloat(actualdetails[x]['Sales']) > 0 && parseFloat(salesPerPlan) >= 3){
            	data[count] = [];
            	let md = actualdetails[x]['Markdowns'] / actualdetails[x]['Sales'];
            	let sales = parseFloat(actualdetails[x]['Sales']) - (parseFloat(actualdetails[x]['Sales']) * Math.abs(md - (annual_markdown / 100)));
            	if((md > (annual_markdown / 100)) && sales > 0){
					data[count]['Sales'] = sales;
            	}else if(md <= (annual_markdown / 100)){
	                data[count]['Sales'] = parseFloat(actualdetails[x]['Sales']);
            	}
            	data[count]['SalesPercentPlan'] = parseFloat(salesPerPlan) / 100;
            	count++;
                if(count > 6){
                    break;
                }
            }
        }

        if(typeof data[1] !== 'undefined'){
			let lg1 = getBaseLog(100, 100 / data[1]['SalesPercentPlan']);
			calc[0] = (data[1]['Sales'] * lg1) / data[1]['SalesPercentPlan'];
		}
		if(typeof data[2] !== 'undefined'){
			calc[1] = ((data[1]['Sales'] + data[2]['Sales']) / (data[1]['SalesPercentPlan'] + data[2]['SalesPercentPlan']));
		}
		if(typeof data[3] !== 'undefined'){
			calc[2] = ((data[1]['Sales'] + data[2]['Sales'] + data[3]['Sales']) / (data[1]['SalesPercentPlan'] + data[2]['SalesPercentPlan'] + data[3]['SalesPercentPlan']));
		}
		if(typeof data[2] !== 'undefined'){
			let lg2 = getBaseLog(100, 100 / data[2]['SalesPercentPlan']);
			calc[3] = ((data[2]['Sales'] * lg2) / data[2]['SalesPercentPlan']);
		}
		if(typeof data[3] !== 'undefined'){
			calc[4] = ((data[2]['Sales'] + data[3]['Sales']) / (data[2]['SalesPercentPlan'] + data[3]['SalesPercentPlan']));
		}
		if(typeof data[4] !== 'undefined'){
			calc[5] = ((data[2]['Sales'] + data[3]['Sales'] + data[4]['Sales']) / (data[2]['SalesPercentPlan'] + data[3]['SalesPercentPlan'] + data[4]['SalesPercentPlan']));
		}
		if(typeof data[3] !== 'undefined'){
			let lg3 = getBaseLog(100, 100 / data[3]['SalesPercentPlan']);
			calc[6] = ((data[3]['Sales'] * lg3) / data[3]['SalesPercentPlan']);
		}
		if(typeof data[4] !== 'undefined'){
			calc[7] = ((data[3]['Sales'] + data[4]['Sales']) / (data[3]['SalesPercentPlan'] + data[4]['SalesPercentPlan']));
		}
		if(typeof data[5] !== 'undefined'){
			calc[8] = ((data[3]['Sales'] + data[4]['Sales'] + data[5]['Sales']) / (data[3]['SalesPercentPlan'] + data[4]['SalesPercentPlan'] + data[5]['SalesPercentPlan']));
		}
		if(typeof data[4] !== 'undefined'){
			let lg4 = getBaseLog(100, 100 / data[4]['SalesPercentPlan']);
			calc[9] = ((data[4]['Sales'] * lg4) / data[4]['SalesPercentPlan']);
		}
		if(typeof data[5] !== 'undefined'){
			calc[10] = ((data[4]['Sales'] + data[5]['Sales']) / (data[4]['SalesPercentPlan'] + data[5]['SalesPercentPlan']));
		}
		if(typeof data[6] !== 'undefined'){
			calc[11] = ((data[4]['Sales'] + data[5]['Sales'] + data[6]['Sales']) / (data[4]['SalesPercentPlan'] + data[5]['SalesPercentPlan'] + data[6]['SalesPercentPlan']));
		}
        calc = calc.filter(n => n);
        let calc_amt = Math.round(mypercentile(calc, planData.percentile));
        form.annual_sales.chipProps.calc_trend_sales_3.value = calc_amt;
        form.annual_sales.chipProps.calc_trend_sales_3.current = calc_amt;
    }

    const mypercentile = (data, percentile) => {
        let p = 0;
	    if( 0 < percentile && percentile < 1 ) {
			p = percentile;
	    }else if( 1 < percentile && percentile <= 100 ) {
	        p = percentile * .01;
	    }else {
	        return 0;
	    }
	    let count = data.length;
		if(count == 0){
			return 0;
		}
	    let allindex = ((count-1) * p) + 1;
	    let intvalindex = parseInt(allindex);
	    let floatval = allindex - intvalindex;
		data.sort(function(a, b){return a-b});
		let result = 0;
		if(isInt(floatval)){
			if(typeof data[intvalindex] !== 'undefined'){
				result = data[intvalindex];
			}else{
				result = data[intvalindex - 1];
			}
	    }else {
	        if(count > intvalindex+1)
	            result = floatval * (data[intvalindex+1] - data[intvalindex]) + data[intvalindex];
	        else
	            if(typeof data[intvalindex] !== 'undefined'){
					result = data[intvalindex];
				}else{
					result = data[intvalindex - 1];
				}
	    }
	    return result;
    }

    const isInt = (value) => {
        var x;
        if (isNaN(value)) {
            return false;
        }
        x = parseFloat(value);
        return (x | 0) === x;
    }

    const markdownsUpdate = (tableKey, rowKey, colIndex, bulkUpdate=false) => {
        const _tableData = cloneDeep(tableData);
        const targetRow = _tableData.get(tableKey).get(rowKey);
        const targetRowInnerData = targetRow.innerData;
        const markdownsPlan = _tableData.get('Markdowns').get('MarkdownsPlan');
        const markdownsPlanVsHistorical = _tableData.get('Markdowns').get('markdowns_plan_vs_historical').innerData;
        const markdownsPlanInnerData = markdownsPlan.innerData;
        const { value, current } = targetRowInnerData[colIndex];
        if(value != current) {
            targetRowInnerData[colIndex].current = value;
            const annualSalesAmt = annualForm.annual_sales.value;
            const annualMarkdownPer = annualForm.annual_markdowns.value / 100;
            const annualMarkdownAmt = +(annualSalesAmt * annualMarkdownPer).toFixed(0);
            const markdownPlanAmt = +(annualMarkdownAmt * (value / 100)).toFixed(0);
            markdownsPlanInnerData[colIndex].value = markdownPlanAmt;
            markdownsPlanVsHistorical[colIndex].value = planData.plandetails[colIndex].avg_markdowns_plan ? (markdownsPlanInnerData[colIndex].value / planData.plandetails[colIndex].avg_markdowns_plan * 100).toFixed(2) : 0
            if (!bulkUpdate) {
                setTableData(_tableData);
            } else {
                tableData = _tableData;
            }
        }
    }

    const stocktosalesplanUpdate = (tableKey, rowKey, colIndex, bulkUpdate=false) => {
        const _tableData = cloneDeep(tableData);
        const targetRow = _tableData.get(tableKey).get(rowKey);
        const targetRowInnerData = targetRow.innerData;
        const inventoryBomPlan = _tableData.get('Inventory').get('InventoryBomPlan');
        const inventoryBomPlanInnerData = inventoryBomPlan.innerData;
        const salesPlan = _tableData.get('Sales').get('SalesPlan');
        const salesPlanInnerData = salesPlan.innerData;
        const markdownsPlan = _tableData.get('Markdowns').get('MarkdownsPlan');
        const markdownsPlanInnerData = markdownsPlan.innerData;
        const _annualForm = cloneDeep(annualForm)

        const { value, current } = targetRowInnerData[colIndex];
        if(value != current) {
            targetRowInnerData[colIndex].current = value;

            if(colIndex > 0 && colIndex < 12) {
                const prevIndex = colIndex - 1;
                const p_inv = inventoryBomPlanInnerData[prevIndex].value;
                const p_sales = salesPlanInnerData[prevIndex].value;
                const p_markdowns = markdownsPlanInnerData[prevIndex].value;
                const p_remainder = p_inv - p_sales - p_markdowns;
                const sales = salesPlanInnerData[colIndex].value;
                const inv = sales * value;
                if(p_remainder >  inv) {
                    inventoryBomPlanInnerData[colIndex].value = +(p_remainder).toFixed(0);
                    inventoryBomPlanInnerData[colIndex].current = +(p_remainder).toFixed(0);
                }else {
                    inventoryBomPlanInnerData[colIndex].value = +(inv).toFixed(0);
                    inventoryBomPlanInnerData[colIndex].current = +(inv).toFixed(0);
                }
            }else if(colIndex == 12) {
                const prevIndex = colIndex - 1;
                const p_inv = inventoryBomPlanInnerData[prevIndex].value;
                const p_sales = salesPlanInnerData[prevIndex].value;
                const p_markdowns = markdownsPlanInnerData[prevIndex].value;
                const p_remainder = p_inv - p_sales - p_markdowns;
                const sales = salesPlanInnerData[0].value;
                const inv = sales * value;
                if(p_remainder >  inv) {
                    inventoryBomPlanInnerData[colIndex].value = +(p_remainder).toFixed(0);
                    inventoryBomPlanInnerData[colIndex].current = +(p_remainder).toFixed(0);
                }else {
                    inventoryBomPlanInnerData[colIndex].value = +(inv).toFixed(0);
                    inventoryBomPlanInnerData[colIndex].current = +(inv).toFixed(0);
                }
            }else if(colIndex == 0 && value > 0) {
                const sales = salesPlanInnerData[0].value;
                const inv = +(sales * value).toFixed(0);
                inventoryBomPlanInnerData[colIndex].value = inv;
                inventoryBomPlanInnerData[colIndex].current = inv;

            }else if(colIndex == 0 && value <= 0) {
                inventoryBomPlanInnerData[colIndex].value = 0;
                inventoryBomPlanInnerData[colIndex].current = 0;
            }
            targetRow.innerData = targetRowInnerData;
            inventoryBomPlan.innerData = inventoryBomPlanInnerData;
            PlanCalculatorService.updateStockToSales(_tableData);
            PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
            PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
            PlanCalculatorService.updateTurn(_tableData, _annualForm);
            if (!bulkUpdate) {
                setTableData(_tableData);
                setAnnualForm(_annualForm);
            } else {
                tableData = _tableData;
                annualForm = _annualForm;
            }
        }
    }

    const inventoryUpdate = (tableKey, rowKey, colIndex, bulkUpdate=false) => {
        const _tableData = cloneDeep(tableData);
        const targetRow = _tableData.get(tableKey).get(rowKey);
        const salesPlan = _tableData.get('Sales').get('SalesPlan');
        const markdownsPlan = _tableData.get('Markdowns').get('MarkdownsPlan');
        const innerData = targetRow.innerData;
        const { value, current } = innerData[colIndex];
        const _annualForm = cloneDeep(annualForm)
        if(current != value) {
            innerData[colIndex].current = value;
            if(colIndex > 0) {
                const prevIndex = colIndex - 1;
                const p_inv = innerData[prevIndex].value;
                const p_sales = salesPlan.innerData[prevIndex].value;
                const p_markdowns = markdownsPlan.innerData[prevIndex].value;
                const p_remainder = p_inv - p_sales - p_markdowns;
                if(p_remainder > value) {
                    innerData[colIndex].value = p_remainder;
                }
            };
            targetRow.innerData = innerData;
        }
        PlanCalculatorService.updateStockToSales(_tableData)
        PlanCalculatorService.updateInflowRetail(_tableData, _annualForm)
        PlanCalculatorService.updateInflowCost(_tableData, _annualForm)
        PlanCalculatorService.updateTurn(_tableData, _annualForm)
        if (!bulkUpdate) {
            setTableData(_tableData);
            setAnnualForm(_annualForm);
        } else {
            tableData = _tableData;
            annualForm = _annualForm;
        }
    }

    const nextLocation = () => {
    	const classesWithSameLocation = _.sortBy(clientBasicData.all_links.filter(item => {
    		return (item.servicetypeid=='1' || item.servicetypeid=='2') && item.clientclass_id == clientBasicData.current_location_class.clientclass_id
    	}), 'locationclassid')
    	let nextIndex = 0
    	classesWithSameLocation.forEach((item, index) => {
    		if( item.client_location_id == clientBasicData.current_location_class.client_location_id ) {
    			nextIndex = ++index
    		}
    	})
    	nextIndex = nextIndex >= classesWithSameLocation.length ? 0 : nextIndex
        if( nextIndex > 0 ) {
            onOptionValueChange({
                location_id: classesWithSameLocation[nextIndex].client_location_id,
                class_id: classesWithSameLocation[nextIndex].clientclass_id,
            })
        } else {
            // Find next class first location
            const _allClasses = _.sortBy(clientBasicData.classes, 'id')
            let nextClassIndex = 0
            _allClasses.forEach((c, index) => {
                if( c.id == clientBasicData.current_location_class.clientclass_id ) {
                    nextClassIndex = ++index
                }
            })
            nextClassIndex = Math.min(_allClasses.length-1, nextClassIndex)
            const nextClass = _allClasses[nextClassIndex]
            const nextClassLocations = _.sortBy(clientBasicData.all_links.filter(item => {
                return (item.servicetypeid=='1' || item.servicetypeid=='2') && item.clientclass_id == nextClass.id
            }), 'locationclassid')
            if( nextClassLocations.length > 0 ) {
                onOptionValueChange({
                    location_id: nextClassLocations[0].client_location_id,
                    class_id: nextClassLocations[0].clientclass_id,
                })    
            }
        }
    }

    const nextClass = () => {
        const locationsWithSameClass = _.sortBy(clientBasicData.all_links.filter(item => {
    		return (item.servicetypeid=='1' || item.servicetypeid=='2') && item.client_location_id == clientBasicData.current_location_class.client_location_id
    	}), 'code')
    	let nextIndex = 0
    	locationsWithSameClass.forEach((item, index) => {
    		if( item.clientclass_id == clientBasicData.current_location_class.clientclass_id ) {
    			nextIndex = ++index
    		}
    	})
    	nextIndex = nextIndex >= locationsWithSameClass.length ? 0 : nextIndex
        if( nextIndex > 0 ) {
        	onOptionValueChange({
        		location_id: locationsWithSameClass[nextIndex].client_location_id,
        		class_id: locationsWithSameClass[nextIndex].clientclass_id,
        	})
        } else {
            // Find next location first class
            const _allLocations = _.sortBy(clientBasicData.locations, 'code')
            let nextLocationIndex = 0
            _allLocations.forEach((c, index) => {
                if( c.id == clientBasicData.current_location_class.client_location_id ) {
                    nextLocationIndex = ++index
                }
            })
            nextLocationIndex = Math.min(_allLocations.length-1, nextLocationIndex)
            const nextLocation = _allLocations[nextLocationIndex]
            const nextLocationClasses = _.sortBy(clientBasicData.all_links.filter(item => {
                return (item.servicetypeid=='1' || item.servicetypeid=='2') && item.client_location_id == nextLocation.client_location_id
            }), 'locationclassid')
            if( nextLocationClasses.length > 0 ) {
                onOptionValueChange({
                    location_id: nextLocationClasses[0].client_location_id,
                    class_id: nextLocationClasses[0].clientclass_id,
                })    
            }
        }
    }

    const getSubmitData = () => {
        const requestData = PlanCalculatorService.getSubmitData(planData, annualForm, tableData)
        return requestData;
    }

    const getBaseLog = (x, y) => {
		return Math.log(x) / Math.log(y);
	}

    const updatePlan = async() => {
    	try {
    		setSingleSaving(true)
    		await PlanService.saveLocationClassBasePlan( client.Id, clientBasicData.current_location_class.locationclassid, getSubmitData() )
    		messageContext.showSuccess('Plan save successfully')
    	} catch(e) {
    		messageContext.show(e.response?.data?.message)
    	}
    	setSingleSaving(false)
    }

    const reloadPlan = () => {
    	initPlanData()
    }

    const toggleHistoricalData = async(yearmonth) => {
        if (historicalPeriodDatas.find(item => item.yearmonth == yearmonth)) {
            let cloneHistoricalPeriodDatas = []
            historicalPeriodDatas.forEach(item => {
                if (item.yearmonth != yearmonth) {
                    cloneHistoricalPeriodDatas.push(item);
                }
            })
            setHistoricalPeriodDatas(cloneHistoricalPeriodDatas)
        } else {
            try {
                setLoadingHistoricalData(true)
                let data = await PlanService.getLocationClassBasePlanDetail(client.Id, clientBasicData.current_location_class.locationclassid, yearmonth)
                data['yearmonth'] = yearmonth;
                let cloneHistoricalPeriodDatas = _.clone(historicalPeriodDatas)
                cloneHistoricalPeriodDatas.push(data);
                cloneHistoricalPeriodDatas = _.sortBy(cloneHistoricalPeriodDatas, 'yearmonth');
                setHistoricalPeriodDatas(cloneHistoricalPeriodDatas)
            } catch(e) {
            }
            setLoadingHistoricalData(false)
        }
    }

    const showCopyToModal = () => {
        setDisplayCopyToModal(true)
    }

    const showCopyFromModal = () => {
        setDisplayCopyFromModal(true)
    }

    const confirmLoadFromPlan = async({type, locationClass}) => {
        if (locationClass) {
            let data;
            if (type=='base') {
                data = await PlanService.getLocationClassBasePlanDetail(client.Id, locationClass.id)
            } else if(type=='history') {
                data = await PlanService.getLocationClassHistoryPlanDetail(client.Id, locationClass.id)
            }
            // Only replace input datas
            const _tableData = cloneDeep(tableData);
            [['Sales', 'SalesPercentPlan'], ['Inventory', 'StockToSalesPlan'], ['Inventory', 'InventoryBomPlan'], ['Markdowns', 'MarkdownsPercentPlan']].forEach(rowItem => {
                data.plandetails.forEach((item, index) => {
                    const _row = _tableData.get(rowItem[0]).get(rowItem[1])
                    const updatedInnerData = _row.innerData;
                    updatedInnerData[index].value = item[rowItem[1]];
                    _row.innerData = updatedInnerData
                })    
            })
            triggerRecalculate.current = true;
            setTableData(_tableData);
        }
        setDisplayCopyFromModal(false)
    }

    const recalculateAllData = () => {
        for (let i=0; i<=11; i++) {
            salesUpdate('Sales', 'SalesPercentPlan', i, i!=11)
        }
        for (let i=0; i<=12; i++) {
            stocktosalesplanUpdate('Inventory', 'StockToSalesPlan', i, i!=12)
        }
        for (let i=0; i<=12; i++) {
            inventoryUpdate('Inventory', 'InventoryBomPlan', i, i!=12)
        }
        for (let i=0; i<=11; i++) {
            markdownsUpdate('Markdowns', 'MarkdownsPercentPlan', i, i!=12)
        }
        triggerRecalculate.current = false
    }

    if( loading ) {
    	return <Box sx={ModifyStyle.loadingSection}>
            <CircularProgress color="primary" size={50} />
        </Box>
    }

    if( !loading && loadError ) {
    	return <Box sx={ModifyStyle.loadingSection}>
    		<Box><GppMaybeOutlinedIcon sx={{ fontSize: 50 }} /></Box>
            <Box>{loadError}</Box>
        </Box>
    }

	return <>
        <Box sx={ModifyStyle.leftSection}>
            <Box sx={ModifyStyle.leftContent}>
                <Box sx={ModifyStyle.loadPlan}>
                    <Button onClick={showCopyFromModal} fullWidth variant="outlined">Load From</Button>
                    <PlanCopyFromModal 
                        open={displayCopyFromModal}
                        onClose={() => setDisplayCopyFromModal(false)}
                        onConfirm={confirmLoadFromPlan}
                        defaultClassId={clientBasicData.current_location_class.clientclass_id}
                        loading={copyFromLoading}
                    />
                    <LoadingButton 
                        onClick={showCopyToModal}
                        variant="outlined"
                        fullWidth
                        sx={{mt: 1}}
                    >
                        Copy To
                    </LoadingButton>
                    <PlanCopyToModal
                        open={displayCopyToModal}
                        annualform={annualForm}
                        tabledata={tableData}
                        plandata={planData}
                        onClose={() => setDisplayCopyToModal(false)}
                    />
                </Box>
            </Box>
        </Box>
        <Box sx={ModifyStyle.rightSection}>
            <Grid container direction="column" sx={ModifyStyle.searchArea}>
                <Grid item container spacing={2}>
                    {Object.keys(annualForm).map((key) => {
                        const searchItem = annualForm[key];
                        return (
                            <Grid key={key} item xs={12} sm={6} md={3}>
                                <TextField
                                    onChange={({target}) => updateAnnualForm(key, {value: target.value})}
                                    onBlur={(e) => onAnnualFormBlur(key, searchItem)}
                                    value={searchItem.value}
                                    label={searchItem.label}
                                    size="small"
                                    fullWidth
                                />
                                <Box classes='searchChips'>
                                    {Object.keys(searchItem.chipProps)?.map(chipKey => {
                                        const chipItem = searchItem.chipProps[chipKey];
                                        return (
                                            <Chip 
	                                            key={`${key}-${chipKey}`} 
	                                            style={ModifyStyle.chipsItem} 
	                                            size='small' 
	                                            onClick={() => onAnnualFormBlur(key, chipItem)}
	                                            label={`${chipItem.label} ${numeral(chipItem.value).format('0,0.00')}`} 
	                                            sx={{mt: 1, mr: 1}}
	                                        />
                                        )
                                    })}
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid item container sx={ModifyStyle.searchButtons}>
                    <Button size='small' variant="contained" onClick={reloadPlan}>UNDO / RELOAD</Button>
                    <Button size='small' variant="contained" sx={{mr: 2}} onClick={getKFlow}>K-FLOW</Button>
                    <ButtonGroup variant="outlined" sx={{mr:2}}>
                    {[1,2,3].map((backYear) => {
                        const _year = moment().subtract(backYear, 'years')
                        const _yearMonth = _year.format('YYYYMM')
                        return (
                            <LoadingButton
                                key={`backYear${backYear}`}
                                loading={loadingHistoricalData}
                                variant={historicalPeriodDatas.find(item => item.yearmonth == _yearMonth) ? 'contained' : 'outlined'}
                                disabled={_.pluck(clientBasicData?.historical_periods, 'yearmonth').indexOf(_yearMonth)==-1}
                                onClick={() => toggleHistoricalData(_yearMonth)}
                            >
                                {_year.format('YYYY')}
                            </LoadingButton>
                        );
                    })}
                    </ButtonGroup>
                </Grid>
            </Grid>
            <Box sx={{pb: 8}}>
                <BigTable 
                    tableData={tableData}
                    historicalPeriodDatas={historicalPeriodDatas}
                    displayMonths={displayMonths}
                    colorMap={colorMap}
                    onInputBlur={(tableKey, innerKey, valIndex) => onBlurTableForm(tableKey, innerKey, valIndex)}
                    onInputChange={(e, tableKey, innerKey, valIndex) => updateTableForm(e, tableKey, innerKey, valIndex)}
                />
            </Box>
            <Box sx={{
                py: 1, 
                pl: 1, 
                position: 'fixed', 
                bottom: 0, 
                backgroundColor: '#f3f4f5', 
                borderRadius: '5px 5px 0 0',
                boxShadow: '0 -1px 5px rgb(162 166 187 / 40%)',
            }}>
                <LoadingButton loading={singleSaving} onClick={updatePlan} variant="contained" sx={ModifyStyle.actionButton}>UPDATE PLAN</LoadingButton>
                <Button onClick={nextLocation} variant="outlined" sx={ModifyStyle.actionButton}>NEXT LOCATION</Button>
                <Button onClick={nextClass} variant="outlined" sx={ModifyStyle.actionButton}>NEXT CLASS</Button>
            </Box>
        </Box>
     </>
}

export default BasePlanEdit