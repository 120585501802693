import { track } from '@amplitude/analytics-browser';

const checkEnv = () => {
    if (process.env.REACT_APP_APP_ENV !== 'production') {
        console.log(`Skip tracking event due to env: ${eventName}`);
        return false
    }

    return true
}

const trackBasicEvent = (eventName) => {
    if (!checkEnv()) {
        return
    }
    
    track(eventName);
}

const trackEvent = (eventName, eventData) => {
    if (!checkEnv()) {
        return
    }

    track(eventName, eventData);
}

export {
    trackBasicEvent,
    trackEvent,
}