import {useState, useEffect} from 'react'
import ClientContext from "../../../../providers/CurrentClientProvider"
import ClientService from '../../../../services/ClientService'

import {
    Button,
    Card,
    Typography,
    CardContent,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from "@mui/material"
import LinearProgress from '@mui/material/LinearProgress';
import UploadIcon from "@mui/icons-material/Upload"
import DownloadIcon from "@mui/icons-material/Download"
import CheckIcon from '@mui/icons-material/Check'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const cssInJs = {
    header: {
        backgroundColor: "#422e52",
        color: "#fff",
        padding: "20px",
        display: "flex",
        justifyContent: "space-between",
    },
    subheader: {
        color: "#fff",
    },
    headerLink: {
        color: "#fff",
    },
    headerIcon: {
        color: "white",
        cursor: "pointer",
        marginRight: "10px",
    },
    heading: {
        fontSize: '15px',
        fontWeight: '500'
    },
    hiddenInput: {
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    },
    errorHeading: {
        textTransform: 'uppercase',
        color: '#d72828',
        fontSize: '1rem'
    },
    error: {
        backgroundColor: '#f5c7c7',
        color: '#d72828',
        padding: '15px'
    }
}

function StepUploadClass( {setDisableContinue} ) {
    const client_context = ClientContext.useClient()
    const [tableIsVisible, setTableIsVisible] = useState(false)
    const [progress, setProgress] = useState(0)
    const [clientChangeLog, setClientChangeLog] = useState({})
    const [client, setClient] = useState({});
    const [locationsExist, setLocationsExist] = useState(false)
    const [uploadError, setUploadError] = useState('')
    const [file, setFile] = useState(null)

    const onUploadProgress = (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 50
        setProgress(progress)
    }

    const onSubmit = async () => {
        // uploading file
        // https://www.retailorbit.com/api/file/uploadclientchangelog on prod

        setUploadError('')
        setClientChangeLog({})
        setTableIsVisible(false)
        try {
            const response = await ClientService.uploadClassFile(client_context.client.Id, file)
            setClientChangeLog(response)
            setTableIsVisible(true)
            setProgress(100)
            setDisableContinue(true)
            await ClientService.processClientChangeLog(response.client.Id,response.id)
            setDisableContinue(false)
        } catch (error) {
            setDisableContinue(false)
            setUploadError(error.response?.data?.message || 'Something went wrong with the file upload')
        }

        setFile(null)
    }

    // check if locations are already present
    useEffect(() => {
        const checkLocations = async () => {
            try {
                const clientData = await ClientService.getClientByCode(client_context.client.ClientCode)
                if (clientData.locations.length > 0) {
                    setLocationsExist(true)
                }
            } catch (error) {
                console.error('Error fetching client data:', error)
            }
        }
        checkLocations()
    }, [])

    // workaround to submit form immediately after file selected
    useEffect(() => {
        if (file) {
            onSubmit()
        }
    }, [file])

    const handleDownload = async() => {
        await ClientService.downloadClientChangeLog(client.Id,clientChangeLog.id)
    }

    return (
        <>
            <Card>
                <div style={cssInJs.header}>
                    <span>Import Classes</span>
                    {/* <a href="#" style={cssInJs.headerLink}>
                       Download Setup Package
                    </a> */}
                </div>
                <CardContent>
                    <Button component="label" variant="contained" startIcon={<UploadIcon/>}>
                        Upload file
                        <input style={cssInJs.hiddenInput} type="file" onChange={(e) => {setFile(e.target.files[0]); e.target.value = null}} />
                    </Button>
                    {locationsExist ?
                        <Typography variant="body1" sx={{ mt:2 }}>
                            If you are <strong>continuing a previous setup</strong>, please be sure locations don't already exist. (Hit complete step and check step 3, you can come back if they don't exist)
                        </Typography>
                    : null}
                    <LinearProgress variant="determinate" value={progress} sx={{ mt:2 }}/>
                </CardContent>
                {uploadError && (
                    <CardContent sx={cssInJs.error}>
                        <Typography variant="h6" gutterBottom sx={cssInJs.errorHeading}>
                            {uploadError}
                        </Typography>
                    </CardContent>
                )}
            </Card>
            {tableIsVisible && (
                <Card>
                    <div style={cssInJs.header}>
                        <div>
                            <span style={{marginRight: "10px"}}>Class Change</span>
                            <span>File Processed Successfully</span>
                        </div>
                        <DownloadIcon
                           sx={cssInJs.headerIcon}
                           onClick={handleDownload}
                        />
                    </div>

                    <CardContent sx={{padding: '15px 20px'}}>
                        <Typography variant="h4" gutterBottom sx={cssInJs.heading}>
                            Information
                        </Typography>

                        <TableContainer>
                            <Table size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Classes</TableCell>
                                        <TableCell>OTB</TableCell>
                                        <TableCell>PNOTB</TableCell>
                                        <TableCell>NOTB</TableCell>
                                        <TableCell>Other</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    <TableRow
                                        sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                    >
                                        <TableCell>{clientChangeLog.client.ClientCode}</TableCell>
                                        <TableCell>{clientChangeLog.client.Name}</TableCell>
                                        <TableCell>{'- '}</TableCell>
                                        <TableCell>{clientChangeLog.client.OtbRate}</TableCell>
                                        <TableCell>{clientChangeLog.client.PnotbRate}</TableCell>
                                        <TableCell>{clientChangeLog.client.NotbRate}</TableCell>
                                        {/*<TableCell>{client.otherCount}</TableCell>*/}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div style={{marginTop: '15px', marginBottom: '15px'}}>File Uploaded Successfully. Processing upload now</div>

                        <TableContainer>
                            <Table size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><CheckBoxIcon/></TableCell>
                                        <TableCell>Action</TableCell>
                                        <TableCell>Location Code</TableCell>
                                        <TableCell>Location Name</TableCell>
                                        <TableCell>Class Code</TableCell>
                                        <TableCell>Class Name</TableCell>
                                        <TableCell>Parent Code</TableCell>
                                        <TableCell>Parent Location</TableCell>
                                        <TableCell>Service</TableCell>
                                        <TableCell>Category</TableCell>
                                    </TableRow>
                                </TableHead>
                                {clientChangeLog.actions && clientChangeLog.actions.length > 0 && (
                                    <TableBody>
                                        {clientChangeLog.actions.map((action) => (
                                            <TableRow
                                                key={action.id}
                                                sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                            >
                                                <TableCell><CheckIcon/></TableCell>
                                                <TableCell>{action.action}</TableCell>
                                                <TableCell>{action.location_code}</TableCell>
                                                <TableCell>{action.location_name}</TableCell>
                                                <TableCell>{action.class_code}</TableCell>
                                                <TableCell>{action.class_name}</TableCell>
                                                <TableCell>{action.parent_class_code}</TableCell>
                                                <TableCell>{action.parent_location_code}</TableCell>
                                                <TableCell>{action.service_type}</TableCell>
                                                <TableCell>{action.class_category}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            )}
        </>
    )
}

export default StepUploadClass
