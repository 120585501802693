import React, {useState} from 'react'
import {
    Link,
} from "react-router-dom";
import {
    Button,
    Card,
    Typography,
    CardContent,
} from "@mui/material"
import ClientContext from "../../../../providers/CurrentClientProvider";
import ClientService from "../../../../services/ClientService";
import { trackEvent } from '../../../../services/AmplitudeService';
import { track } from '@amplitude/analytics-browser';

const cssInJs = {
    header: {
        backgroundColor: "#fafafa",
        color: "black",
        padding: "20px",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: '1px rgba(92,63,117,0.1) solid'

    },
    headerLink: {},
}

const ApprovalStep = ({ clientInfo, onApproval }) => {

    const [isApproved, setIsApproved] = useState(false);
    const client_context = ClientContext.useClient();

    const handleSubmitForApproval = async () => {
        try {
            const response = await ClientService.submitForApproval(client_context.client.ClientCode);
            setIsApproved(true);

            trackEvent('Submitted Client For Approval', {
                clientCode: client_context.client.ClientCode
            });
        } catch (error) {
            console.error('Error fetching client data:', error);
        }
    }

    if (onApproval) {
        onApproval();
    }

    return (
        <div>
            {isApproved ? (
                <div className="material-card">
                    <div className="material-card-header">
                        <h3>Client Submitted For Approval</h3>
                    </div>
                    <div className="material-card-body">
                        <h4 className="ng-binding">{client_context.client.ClientCode} - {client_context.client.Name} has been submitted for approval.</h4>
                        <Link
                            to={`/clients/${client_context.client.ClientCode}/profile`}
                            underline="hover"
                            sx={{ textTransform: 'uppercase'}}
                        >
                            Go to profile
                        </Link>
                    </div>
                </div>
            ) : (
                <Card>
                    <div style={cssInJs.header}>
                        <span>Complete Setup</span>
                    </div>
                    <CardContent>
                        <h4>Please review your billing, locations, and classes to make sure everything looks correct.</h4>
                        <h4>You will still need to include the following:</h4>
                        <ul>
                            <li>24 months Data History</li>
                            <li>Financial Statement</li>
                            <li>Breakeven</li>
                            <li>PPW</li>
                            <li>Do you need the plans this month?</li>
                        </ul>
                        <h4>If everything is ready you can now submit your setup for approval.</h4>
                        <button onClick={handleSubmitForApproval}>Submit for Approval</button>
                    </CardContent>
                </Card>
            )}
        </div>
    );
};

export default ApprovalStep