import React, {useEffect, useState} from "react";
import Picker from 'react-month-picker'
import TableauService from "../../services/TableauService";
import ClientService from "../../services/ClientService";
import CurrentClientContext from "../../providers/CurrentClientProvider";
import {Box, Button, Grid, List, ListItem, ListItemText} from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TableauReport from "../../components/tableau/TableauReport";
import {useParams} from "react-router-dom";
import AuthContext from "../../providers/AuthProvider";
import { trackEvent } from "../../services/AmplitudeService";

function ClientAnalytics(props) {
    const auth = AuthContext.useAuth()
    const currentClient = CurrentClientContext.useClient()
    let { client_code, clientId } = useParams()
    const [projects, setProjects] = useState([])
    const [filteredProjects, setFilteredProjects] = useState([])
    const [tableauReport, setTableauReport] = useState(null)
    const [lastMonthDataFileFinalized, setLastMonthDataFileFinalized] = useState(false)
    const [epochMonth, setEpochMonth] = useState(-1) // -1 is current month in tableau
    const pickerLang = {
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        from: 'From', to: 'To',
    }
    const fullMonth = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const pickAMonth = React.createRef()
    const date = new Date()
    const [currentMonth, setCurrentMonth] = useState({year: date.getFullYear(), month: date.getMonth() + 1})

    useEffect(async () => {
        document.title = 'Retail ORBIT: Client Analytics'
    }, [])

    function getMinDateForFilter() {
        let date = new Date();
        date.setMonth(date.getMonth()-48);
        return date;
    }

    function getMaxDateForFilter() {
        let date;
        if (process.env.REACT_APP_REPORT_LOCKOUT_CURRENT_MONTH !== 'true' && lastMonthDataFileFinalized) {
            date = new Date();
            date.setMonth(date.getMonth()+12);
        } else {
            // set max to last month and deploy
            date = new Date();
            date.setMonth(date.getMonth()-1);
        }

        return date;
    }

    useEffect(async () => {
        if (currentClient.client) {
            getProjects()

            // get last month data finalized status
            let _lastMonthDataFileFinalized = await ClientService.getLastMonthDataFileFinalized(currentClient.client.Id)

            if (_lastMonthDataFileFinalized === null) {
                _lastMonthDataFileFinalized = false
            } else {
                _lastMonthDataFileFinalized = _lastMonthDataFileFinalized.last_month_data_file_finalized
            }

            setLastMonthDataFileFinalized(_lastMonthDataFileFinalized)

            if (process.env.REACT_APP_REPORT_LOCKOUT_CURRENT_MONTH === 'true' || !_lastMonthDataFileFinalized) {
                // set current month to last month (month index already starts at -1 from current month)
                let date = new Date()
                setCurrentMonth({year: date.getFullYear(), month: date.getMonth()})
            }
        }
    }, [currentClient.client])

    function filterExports(workbooks) {
        // filters workbooks from export project based on faves and shared custom flags set in profile
        return workbooks.filter(wb => !wb.name.toLowerCase().includes('mrf') &&
                !wb.name.toLowerCase().includes('goals') &&
                !wb.name.toLowerCase().includes('faves') &&
                !wb.name.toLowerCase().includes('mke'));
    }

    async function getProjects() {
        let filter1 = '+'
        let filter2 = 'live'
        let projectSort = {
            'Analytics': 0,
            'Progress': 1,
            'Take Action': 2,
            'Plan': 3,
            'My Reports': 4,
            'Export': 5,
        };
        let _projects = await TableauService.getProjects(clientId);

        _projects.sort(
            (a, b) => projectSort[a.name] - projectSort[b.name]
        )
        setProjects(_projects)
        if (_projects.length > 0) {
            let _p = structuredClone(_projects)
            let _f = filterProjects(_p, filter1, filter2)
            setFilteredProjects(_f)
        }

    }

    function filterProjects(p, filter1, filter2) {
        let analyticsWorkbookSort = {
            'Global Analytics': 0,
            'Buyer Performance': 1,
            'Class Performance': 2,
            'Inventory Performance': 3
        };
        let takeActionWorkbookSort = {
            'Inventory Action': 0,
            'Open To Buy Recap': 1,
            'Inventory Variance': 2
        };
        p.forEach((project) => {
            if (project.name.toLowerCase().includes('export')) {
                project.workbooks = filterExports(project.workbooks);
            } else if (project.name.toLowerCase().includes('progress') && (filter2 === 'live' || filter2 === '+')) {
                project.workbooks =
                    project.workbooks.filter(data => !data.name.toLowerCase().includes(filter1))
            } else {
                project.workbooks =
                    project.workbooks.filter(data => !data.name.toLowerCase().includes(filter1)
                        && !data.name.toLowerCase().includes(filter2));
            }
            if (project.name === 'Analytics') {
                project.workbooks.sort(
                    (a, b) =>
                        analyticsWorkbookSort[a.name] - analyticsWorkbookSort[b.name]
                );
            } else if (project.name === 'Take Action') {
                project.workbooks.sort(
                    (a, b) =>
                        takeActionWorkbookSort[a.name] - takeActionWorkbookSort[b.name]
                );
            }
            // else if (project.name === 'ClientReports2') {
            //     project.workbooks.sort(
            //         (a, b) => takeActionWorkbookSort[a.name] - takeActionWorkbookSort[b.name]
            //     );
            // }
        })
        return p
    }

    /**
     * Get Epoch Month from Current Date
     * 
     * @param {*} date 
     * @param {*} noNegativeOne Whether or not to allow returning -1
     * @returns 
     */
    function getEpochMonthFromDate(date, noNegativeOne = false){
        const epochDate = new Date('01 January 1970 00:00');

        if (!noNegativeOne) {
            // if date is current month, return -1
            let currentDate = new Date();
            if (date.getFullYear() === currentDate.getFullYear() && date.getMonth() === currentDate.getMonth()) {
                return -1;
            }
        }

        return (date.getMonth() - epochDate.getMonth() + 12 *
            (date.getFullYear() - epochDate.getFullYear()))
    }

    function pickWorkbook(item) {
        let _epochMonth = epochMonth
        if (item.project.name === clientId) {
            // don't use -1 epochmonth
            _epochMonth = getEpochMonthFromDate(new Date(currentMonth.year, currentMonth.month - 1, 1, 0, 0, 0, 0), true)
        }

        trackEvent('Generated Report ' + item.name, {
            properties: {
                generatedFrom: 'Tableau',
                reportName: item.name,
                epochMonth: _epochMonth,
                clientId: clientId,
                clientName: currentClient.client.Name,
                clientCode: currentClient.client.Code,
            }
        })


        setTableauReport({url: item.views[0].contentUrl + '?:toolbar=top&:alerts=n&:subscriptions=n', clientId: clientId, epochMonth: _epochMonth})
    }

    function handleAMonthChange(year, month) {
        setCurrentMonth({year: year, month: month})
    }

    function handleAMonthDissmis(val) {
        setCurrentMonth(val)
        setEpochMonth(getEpochMonthFromDate(new Date(val.year, val.month - 1, 1, 0, 0, 0, 0)))
        updateWorkbooks()
    }

    function disableTableau() {
        setTableauReport(null);
    }

    function updateWorkbooks() {
        let date = new Date()
        let _p = structuredClone(projects);
        if (date.getFullYear() === currentMonth.year) {
            if (date.getMonth() + 1 - currentMonth.month === 1 || currentMonth.month - (date.getMonth() + 1) === 1) {
                setFilteredProjects(filterProjects(_p,'.', 'live'));
            } else if (currentMonth.month === date.getMonth() + 1) {
                setFilteredProjects(filterProjects(_p,'+', 'live'));
            } else {
                setFilteredProjects(filterProjects(_p, '.', '+'));
            }
        } else {
            setFilteredProjects(filterProjects(_p, '.', '+'));
        }
    }

    function handleClickOnMonth() {
        pickAMonth.current.show()
    }

    return <Box sx={{mt: 1}}>
        <Grid container sx={{backgroundColor: '#fff', minHeight: '300px'}}>
            <Grid item lg={12}>
                <Picker
                    ref={pickAMonth}
                    years={{min: {year: getMinDateForFilter().getFullYear(), month: getMinDateForFilter().getMonth() + 1},
                        max: {year: getMaxDateForFilter().getFullYear(), month: getMaxDateForFilter().getMonth() + 1}}}
                    value={currentMonth}
                    lang={pickerLang.months}
                    onChange={handleAMonthChange}
                    onDismiss={handleAMonthDissmis}
                >
                    <Box sx={jss.date_box} onClick={handleClickOnMonth}>
                        Please select a date
                        <CalendarMonthIcon sx={jss.date_box.ico} /> <Box sx={jss.date_box.underscore_item}>{fullMonth[currentMonth.month - 1] + " " + currentMonth.year}</Box>
                    </Box>
                </Picker>
                { tableauReport && <Button sx={jss.return_button} variant="contained" onClick={disableTableau}>Return to library</Button>}
            </Grid>
            { !tableauReport ? filteredProjects.map(project => {
                const projectName = project.name === clientId ? "My Reports" : project.name;

                return <Grid item sx={jss.box_styles} lg={12 / projects.length} key={project.id}>
                    <h1 style={jss.head_title}>{projectName}</h1>
                    <List sx={{width: '100%'}}>
                        {project.workbooks.map((value) => (
                            <ListItem
                                key={value.id}
                                disableGutters
                                sx={jss.list_styles}
                                onClick={() => pickWorkbook(value)}
                            >
                                <ListItemText sx={jss.list_item} primary={value.name}/>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            }) : <Grid item lg={12}>
                <TableauReport url={tableauReport.url} clientId={clientId} epochMonth={tableauReport.epochMonth} />
            </Grid> }
        </Grid>
    </Box>
}

const jss = {
    head_title: {
        fontSize: 18,
        textTransform: 'uppercase',
        textAlign: 'center',
        backgroundColor: 'rgb(145, 112, 173)',
        padding: '10px 20px',
        color: '#fff'
    },
    box_styles: {
        padding: '0 15px',
        boxSizing: 'border-box'
    },
    list_styles: {
        padding: '5px 15px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'gainsboro'
        }
    },
    list_item: {},
    date_box: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: '15px',
        marginTop: '15px',
        fontSize: '20px',
        ico: {
            margin: '0 5px 0 15px'
        },
        underscore_item: {
            borderBottom: '1px solid #000',
            cursor: 'pointer',
            fontSize: '14px'
        }
    },
    return_button: {
        textTransform: 'uppercase',
        margin: '5px 0 15px 15px'
    }
}
export default ClientAnalytics