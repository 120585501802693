import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Box,
	Grid,
	Paper,
	Button,
} from '@mui/material';
import ClientService from '../../services/ClientService'
import _ from 'underscore'
import ClientProfileComponent from '../../components/client/ClientProfile'
import ClientNote from '../../components/client/profile/ClientNote'
import ClientProfileClass from '../../components/client/profile/ClientProfileClass'
import ClientLocation from '../../components/client/profile/ClientLocation'
import ClientUser from '../../components/client/profile/ClientUser'
import ClientTags from "../../components/client/profile/ClientTags";
import AuthProvider from "../../providers/AuthProvider";
import SectionTitle from "../../components/common/SectionTitle";
import TipContext from "../../providers/GlobalTip";

const jss = {
	detailSection: {
		py: 2
	},
}

function ClientProfile(props) {
	let { client_code } = useParams()
	const [client, setClient] = useState()
	const navigate = useNavigate()
	const auth = AuthProvider.useAuth()
	const [isAdmin, setIsAdmin] = useState(false)
	const tip = TipContext.useMessage()

	useEffect(() => {
		document.title = 'Retail ORBIT: Client Profile'
	}, [])

	// is admin
	useEffect(() => {
		// role-create marks admin, but we don't have role specific checks currently
		if (auth.checkPermission('role-create')) {
			setIsAdmin(true)
		}
	}, [auth])

	useEffect(() => {
		loadClient()
	}, [client_code])

	/**
	 * Generates all plans for a client
	 * 
	 * @param {Object} e
	 * 
	 * @return {void}
	 */
	const generateNewClassPlans = async (e) => {
		// confirm user knows what this Does
		if (!window.confirm('Are you sure you want to generate new PLACEHOLDER class plans? This will generate empty plans for all plannable locations classes for this client.')) {
			return
		}

		tip.showSuccess('Generating new class plans... this will take several minutes. You may continue browsing.')

		try {
			await ClientService.generateNewClassPlans(client.Id)
		} catch(e) {
			console.log(e)

			// clear current tipContext messages
			tip.clear()

			tip.show(e.response?.data?.message, 5)
		}
	}

	/**
	 * Generate new month plans for the client
	 * 
	 * @param {Object} e
	 * 
	 * @return {void}
	 */
	const generateNewMonthPlans = async (e) => {
		// confirm user knows what this Does
		if (!window.confirm('Are you sure you want to generate new plans for this month? Be sure that end of month data has been finalized for the previous month.')) {
			return
		}

		tip.showSuccess('Generating new month plans... this will take several minutes. You may continue browsing.')

		try {
			await ClientService.generateNewMonthPlans(client.Id)
		} catch(e) {
			console.log(e)

			// clear current tipContext messages
			tip.clear()

			tip.show(e.response?.data?.message, 5)
		}
	}

	/**
	 * Sends email to client notifying that their plans for the new month were published
	 * 
	 * @param {Object} e
	 * 
	 * @return {void}
	 */
	const notifyPlansPublished = async (e) => {
		// confirm user knows what this Does
		if (!window.confirm('Are you sure you want to send a notification to the client that their plans have been published?')) {
			return
		}

		tip.showSuccess('Sending notification to client...')

		try {
			await ClientService.notifyPlansPublished(client.Id)
			tip.showSuccess('Notification sent to client.')
		} catch(e) {
			console.log(e)

			// clear current tipContext messages
			tip.clear()

			tip.show(e.response?.data?.message, 5)
		}
	}

	async function loadClient() {
		try {
			const _client = await ClientService.getClientByCode(client_code)
			setClient(_client)
		} catch(e) {
			navigate('/')
		}
	}

	return <Grid container spacing={2} sx={jss.detailSection}>
		<Grid item xs={12} sm={6}>
			{isAdmin ? 
				<Paper elevation={2} sx={{mb:2}}>
					<Box sx={{mb:2}} spacing={2}>
						<SectionTitle label="Client Admin Tools" type="primary"/>
					</Box>
					<Button variant="contained" onClick={generateNewClassPlans} sx={{ml: 2, mb: 2}}>Generate Plans for New Classes</Button>
					{client?.IsManualEntry ? <Button variant="contained" onClick={generateNewMonthPlans} sx={{ml: 2, mb: 2}}>Generate Plans for New Month</Button> : null}
					<Button variant="contained" onClick={notifyPlansPublished} sx={{ml: 2, mb: 2}}>Send Plans Published Notification</Button>
				</Paper>
			: null}
			<ClientProfileComponent client={client} onSave={e => setClient(e)} isAdmin={isAdmin} />
		</Grid>
		<Grid item xs={12} sm={6}>
			{isAdmin ?
				<Box sx={{mb:2}}>
					<ClientNote client={client} />
				</Box>
			: null}
			<Box sx={{mb:2}}>
				<ClientTags client={client} isAdmin={isAdmin} />
			</Box>
			<Box sx={{mb:2}}>
				<ClientProfileClass client={client} isAdmin={isAdmin} />
			</Box>
			<Box sx={{mb:2}}>
				<ClientLocation client={client} isAdmin={isAdmin} />
			</Box>
			<Box sx={{mb:2}}>
				<ClientUser client={client} isAdmin={isAdmin} />
			</Box>
		</Grid>
	</Grid>
}
export default ClientProfile