import {
	useState,
	useEffect,
	useRef,
	useMemo,
} from "react";
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Box,
	Typography,
	Grid,
	IconButton,
	Paper,
	Switch,
	CircularProgress,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	Chip,
	Menu,
	MenuItem,
	Fade,
	Collapse,
	Alert,
	TextField,
	Autocomplete, RadioGroup, FormControlLabel, Radio, FormControl,
} from '@mui/material';
import {
	LoadingButton
} from '@mui/lab'
import SectionTitle from '../../../components/common/SectionTitle'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import LocationService from '../../../services/LocationService'
import LabelService from '../../../services/LabelService'
import ClientService from '../../../services/ClientService'
import moment from 'moment'
import ClearIcon from '@mui/icons-material/Clear';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, DatePicker } from '@mui/x-date-pickers';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import ClassDisplay from './ClassDisplay';
import _ from 'underscore';


const jss = {
	tableLabel: {
		fontWeight: 'bolder',
		width: '25%',
		textAlign: 'right'
	},
	editTitle: {
		fontSize: 16,
		fontWeight: 'bolder',
		mt: 3,
	},
	tagSection: {
		'.MuiChip-root': {
			mr:1,
			mt:1,
		}
	}
}


function ClientClassEdit(props) {
	const {
		locations,
		clientClass,
		allClasses,
		masterCategories,
		labelData,
		categories,
		isAdmin,
		onDeleteChange,
		tip_context,
	} = props
	// const [clientClass, setClientClass] = useState()
	const [loading, setLoading] = useState(false)
	const [editing, setEditing] = useState(false)
	const [saving, setSaving] = useState(false)
	const [has_change, setHasChange] = useState(false)
	const [open_more, setOpenMore] = useState(false)
	const more_element = useRef()
	const [formData, setFormData] = useState()
	// const currentClientContext = CurrentClientContext.useClient()
	// const [labelData, setLabelData] = useState()
	// const [categories, setCategories] = useState()
	// const [masterCategories, setMasterCategories] = useState([])

	useEffect(() => {
		if( clientClass ) {
			setEditing(false)
			setHasChange(false)
			// loadLabels()
			// loadCategories()
			// loadMasterCategories();
			setFormData({
				code: clientClass.code,
				name: clientClass.name,
				service_type_id: clientClass.service_type_id,
				warehouse_location_id: clientClass.warehouse_location_id,
				labels: clientClass.labels?.map(i => {delete(i.pivot);return i}),
				categories: clientClass?.categories.map(i => {delete(i.pivot);return i}),
				parent_class_id: clientClass.parent_class_id,
				imu: clientClass.imu,
				child_classes: clientClass.child_classes,
				master_classes: clientClass?.master_classes.map(i => {delete(i.pivot);return i}),
				LocationClassModelId: clientClass.LocationClassModelId,
			})
		}
	}, [clientClass])

	useEffect(() => {
		if( props.clientClass ) {
			// loadClass()
		}
	}, [props.clientClass])

	async function loadClass() {
		setLoading(true)
		// const data = await ClientService.getClientClassById(props.clientClass.client_id, props.clientClass.id, {
		// 	include: [
		// 		'parentClass',
		// 		'locationLinks',
		// 		'labels',
		// 		'categories',
		// 		'warehouseLocation',
		// 		'childClasses',
		// 		'masterClasses',
		// 	]
		// })
		// setClientClass(data)
		setLoading(false)
	}

	// async function loadLabels() {
	// 	const data = await LabelService.getLabels()
	// 	setLabelData(data)
	// }
	//
	// async function loadCategories() {
	// 	const data = await ClientService.getClientCategories(clientClass.client_id)
	// 	setCategories(data)
	// }
	//
	// async function loadMasterCategories() {
	// 	const data = await ClientService.getMasterCategories();
	// 	setMasterCategories(data)
	// }

	async function undeleteData() {
		try {
			await ClientService.undeleteClassById(clientClass.client_id, clientClass.id)
			setOpenMore(false)
			
			// update the current class with deleted_at and isActive status
			let newClientClass = {...clientClass}
			newClientClass.deleted_at = null
			newClientClass.isActive = 1

			props.onDeleteChange(newClientClass)
		} catch(e) {
			console.log(e)
		}
	}

	async function deleteData() {
		try {
			await ClientService.deleteClassById(clientClass.client_id, clientClass.id)
			setOpenMore(false)

			// update the current class with deleted_at and isActive status
			let newClientClass = {...clientClass}
			newClientClass.deleted_at = moment().format('YYYY-MM-DD HH:mm:ss')
			newClientClass.isActive = 0

			props.onDeleteChange(newClientClass)

		} catch(e) {
			console.log(e)
		}
	}

	const masterClasses = useMemo(() => {
		let t = [];
		masterCategories.forEach(mc => {
			t = [...t, ...mc.master_classes]
		})
		return _.sortBy(t, 'id');
	}, [masterCategories]);

	const common_input_props = {
		fullWidth: true, 
		variant: "outlined",
		size: "small",
		onChange: e => updateFormData(e),
		sx: {mt:2},
	}

	function updateFormData(e) {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		})
		if( !has_change ) {
			setHasChange(true)
		}
	}

	function labelChange(event, newValue) {
		updateFormData({
			target: {
				name: 'labels',
				value: newValue
			}
		})
	}

	function categoryChange(event, newValue) {
		updateFormData({
			target: {
				name: 'categories',
				value: newValue
			}
		})
	}

	function childClassChange(event, newValue, ) {
		updateFormData({
			target: {
				name: 'child_classes',
				value: newValue
			}
		})
	}

	function imuOptionsChange(event, newValue) {
		updateFormData({
			target: {
				name: 'LocationClassModelId',
				value: newValue
			}
		})
	}

	function masterClassChange(e, newValue) {
		updateFormData({
			target: {
				name: 'master_classes',
				value: newValue
			}
		})
	}

	async function saveClass() {
		// if old class service type is in plannable ids and new is not, make sure user is sure
		const plannableIds = ["1", "2"]
		if (plannableIds.includes(clientClass.service_type_id) && !plannableIds.includes(formData.service_type_id)) {
			if( !window.confirm('You are changing the service type from plannable to not plannable, this will remove all the plan data. Are you sure?') ) {
				return
			}
		}
		
		try {
			setSaving(true)
			const _class = await ClientService.updateClassById(clientClass.client_id, clientClass.id, {
				...formData, 
				labels: formData.labels.map(i => {return i.id}),
				categories: formData.categories.map(i => {return i.Id}),
				child_classes: formData.child_classes.map(i => {return i.id}),
				master_classes: formData.master_classes.map(i => {return i.id}),
			})
			setEditing(false)
			setHasChange(false)
			if( props.onSave ) {
				props.onSave(_class)	
			}
		} catch(e) {
			console.log(e.response?.data.message)

			if( tip_context && e.response?.status === 400 && e.response?.data.message ) {
				tip_context.show(e.response?.data.message)
			}
		}
		setSaving(false)
	}

	if( clientClass ) {
		return <Paper elevation={2}>
			<SectionTitle
				label={clientClass.name}
				subLabel="Class profile"
				type="primary"
				rightComponent={
					isAdmin ?
						<>
							{!editing && 
								<IconButton onClick={e => setEditing(true)}>
									<ModeEditOutlineOutlinedIcon sx={{color: 'white'}}/>
								</IconButton>
							}
							{editing && 
								<IconButton onClick={e => setEditing(false)}>
									<KeyboardArrowUpOutlinedIcon sx={{color: 'white'}}/>
								</IconButton>
							}
							<IconButton onClick={e => {setOpenMore(true)}} ref={more_element}>
								<MoreVertOutlinedIcon sx={{color: 'white'}} />
							</IconButton>
							<Menu
								open={open_more}
								anchorEl={more_element.current}
								onClose={e => {setOpenMore(false)}}
							>
								{clientClass.deleted_at && 
									<MenuItem dense onClick={undeleteData}>Undelete class</MenuItem>
								}
								{!clientClass.deleted_at && 
									<MenuItem dense onClick={deleteData}>Drop class</MenuItem>
								}
							</Menu>
						</>
					: null}
			/>
			<Box sx={{p:2,pt:0}}>
				{!editing && <ClassDisplay clientClass={clientClass} reloadData={false}/>}
				{editing && labelData &&
					<Fade timeout={500} in>
						<Box>
							<Collapse in={has_change}>
								<Alert sx={{mt:2}} severity="warning">You have unsaved changes, please hit save before continuing.</Alert>
							</Collapse>

							<Box sx={jss.editTitle}>General</Box>
							<TextField 
								{...common_input_props}
								label="Code" 
								name="code"
								value={formData.code || ''}
							/>
							<TextField 
								{...common_input_props}
								label="Name" 
								name="name"
								value={formData.name || ''}
							/>
							<TextField
								{...common_input_props}
								select
								name="service_type_id"
								value={formData.service_type_id}
								label="Service Type"
							>
		 						<MenuItem value='1'>OTB</MenuItem>
		 						<MenuItem value='2'>PNOTB</MenuItem>
		 						<MenuItem value='3'>NOTB</MenuItem>
		 						<MenuItem value='7'>History</MenuItem>
		 						<MenuItem value='8'>Distress</MenuItem>
		 						<MenuItem value='9'>Warehouse</MenuItem>
		 						<MenuItem value='10'>Child</MenuItem>
							</TextField>
							{formData.labels &&
								<Autocomplete
									multiple
									disableCloseOnSelect
									options={labelData.labels}
									getOptionLabel={(option) => option.name}
									renderOption={(props, option) => {
										return (
										<li {...props} key={`label_${option.id}`}>
											{option.name}
										</li>
										);
									}}
									value={formData.labels}
									filterSelectedOptions
									renderInput={(params) => (
										<TextField
											{...params}
											{...common_input_props}
											label="M1 Tags"
										/>
									)}
									onChange={labelChange}
								/>
							}
							<Autocomplete
								multiple
								disableCloseOnSelect
								options={categories}
								getOptionLabel={(option) => option.Name}
								renderOption={(props, option) => {
									return (
									<li {...props} key={`category_${option.Id}`}>
										{option.Name}
									</li>
									);
								}}
								value={formData.categories}
								filterSelectedOptions
								renderInput={(params) => (
									<TextField
										{...params}
										{...common_input_props}
										label="Categories"
									/>
								)}
								onChange={categoryChange}
							/>
							{formData.service_type_id=='10' && 
								<TextField
									{...common_input_props}
									select
									name="parent_class_id"
									value={formData.parent_class_id}
									label="Parent Class"
								>
								{
									allClasses.filter(item => {
										return !item.parent_class_id
									}).map(item => {
										return <MenuItem value={item.id} key={`parent_class_${item.id}`}>[{item.code}] {item.name}</MenuItem>
									})
								}
								</TextField>
							}
							{formData.service_type_id!='10' &&
								<Autocomplete
									multiple
									disableCloseOnSelect
									options={allClasses.filter(item => !!item.parent_class_id && clientClass.child_classes.every(child => child.id !== item.id))}
									getOptionLabel={(option) => option.name}
									renderOption={(props, option) => {
										return (
										<li {...props} key={`child_class_${option.id}`}>
											[{option.code}] {option.name}
										</li>
										);
									}}
									value={formData.child_classes}
									filterSelectedOptions
									onChange={childClassChange}
									renderInput={(params) => (
										<TextField
											{...params}
											{...common_input_props}
											label="Child Classes"
										/>
									)}
									renderTags={(tagValue) => tagValue.map((option, index) => (
											<Chip
												label={option.name}
												disabled={clientClass.child_classes.indexOf(option) !== -1}
											/>
										))
									}
								/>
							}
							<TextField
								{...common_input_props}
								select
								name="warehouse_location_id"
								value={formData.warehouse_location_id}
								label="Warehouse Location"
							>
							{locations.map(_location => {
								return <MenuItem key={`Warehouse_location_${_location.client_location_id}`} value={_location.client_location_id}>[{_location.location_code}] {_location.location_name}</MenuItem>
							})}
							</TextField>
							<TextField
								{...common_input_props}
								label="Imu"
								name="imu"
								value={formData.imu || 0}
								type="number"
							/>

							<FormControl>
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									value={formData.LocationClassModelId}
									name="radio-buttons-group"
									onChange={imuOptionsChange}
								>
									<FormControlLabel value="0" control={<Radio />} label="Receiving" />
									<FormControlLabel value="1" control={<Radio />} label="On Order" />
									<FormControlLabel value="2" control={<Radio />} label="Plan" />
									<FormControlLabel value="3" control={<Radio />} label="Override" />
								</RadioGroup>
							</FormControl>

							<Autocomplete
								multiple
								options={masterClasses}
								getOptionLabel={(option) => `[${option.id}] ${option.name}`}
								renderOption={(props, option) => {
									return (
									<li {...props} key={`master_class_${option.id}`}>
										[{option.id}] {option.name}
									</li>
									);
								}}
								value={formData.master_classes}
								filterSelectedOptions
								renderInput={(params) => (
									<TextField
										{...params}
										{...common_input_props}
										label="Master Classes"
									/>
								)}
								onChange={masterClassChange}
							/>

							<Box sx={{mt:2}}>
								<LoadingButton loading={saving} size="large" variant="contained" onClick={saveClass} startIcon={<SaveTwoToneIcon />}>
									Save
								</LoadingButton>
							</Box>

						</Box>
					</Fade>
				}
			</Box>
		</Paper>
	} else {
		return <></>
	}
}
export default ClientClassEdit